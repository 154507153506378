import { FunctionComponent } from "react";

import "./styles.scss";

type Props = {
  show: boolean;
  onClose?: () => void;
  style?: React.CSSProperties;
  className?: string;
  closable?: boolean;
};

const Modal: FunctionComponent<Props> = ({
  show,
  children,
  onClose = () => {},
  style,
  className = "",
  closable = true,
}) => {
  if (!show) return null;

  return (
    <div className="modal-component-container">
      <div className="modal-component-holder">
        <div
          className="modal-component-overlay"
          onClick={() => closable && onClose()}
        />
        <div style={style} className={`card-content ${className}`}>
          {closable && (
            <button
              type="button"
              className="btn-close modal-close-button"
              aria-label="Close"
              onClick={() => onClose()}
            />
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export { Modal };
