import isArray from "lodash/isArray";
import { FunctionComponent } from "react";

import "./styles.scss";

interface Action {
  onClick: () => void;
  icon: string;
}

type Props = {
  actions: Action | Action[];
};

const ButtonAction: FunctionComponent<Props> = ({ actions, children }) => (
  <div className="button-actions-container-component justify-content-center d-flex align-items-center">
    {isArray(actions) ? (
      <>
        <div
          className="left-action action"
          onClick={() => actions[0].onClick()}
        >
          <img className="actions-icon" src={actions[0].icon} alt="" />
        </div>
        <div className="value">{children}</div>
        <div
          className="right-action action"
          onClick={() => actions[1].onClick()}
        >
          <img className="actions-icon" src={actions[1].icon} alt="" />
        </div>
      </>
    ) : (
      <div className="center-action action" onClick={() => actions.onClick()}>
        <img className="action-icon" src={actions.icon} alt="" />
      </div>
    )}
  </div>
);

export { ButtonAction };
