import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import "./styles.scss";

const Prices = () => {
  return (
    <div className="view-container">
      <Container fluid="sm">
        <Row className="justify-content-md-center">
          <Col md="12" sm="6">
            <Row className="justify-content-md-center">
              <Col className="img-holder" md="6" sm="12">
                <img
                  className="img"
                  alt="schedule"
                  src="https://firebasestorage.googleapis.com/v0/b/guanathletics.appspot.com/o/GN-Enero-Febrero22-07-07.jpg?alt=media&token=f0a47168-3382-4402-a9be-db6766f87df7"
                />
              </Col>
              <Col className="img-holder" md="6" sm="12">
                <img
                  className="img"
                  alt="schedule"
                  src="https://firebasestorage.googleapis.com/v0/b/guanathletics.appspot.com/o/GN-Enero-Febrero22-08.jpg?alt=media&token=933ff468-0d08-4a34-a1dc-d020e8cb2f85"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Prices;
