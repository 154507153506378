import { FunctionComponent } from "react";

import "./styles.scss";

type Props = {
  style?: React.CSSProperties;
  className?: string;
  flat?: boolean;
};

const Card: FunctionComponent<Props> = ({
  children,
  style,
  className = "",
  flat = false,
}) => (
  <div style={style} className={`card-component-container ${flat ? 'flat' : ''} ${className}`}>
    {children}
  </div>
);

export { Card };
