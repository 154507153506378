import React, { useRef } from "react";

import "./styles.scss";

type Props = {
  placeholder: string;
  onChange: (value: string) => void;
  value?: string;
  error?: string;
  showError?: boolean;
  type?: "text" | "email" | "password" | "number";
  name?: string;
};

const InputText = ({
  placeholder,
  onChange,
  value = "",
  error,
  showError = false,
  type = "text",
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const isNumber = type === "number";
  const setFocus = () => inputRef.current && inputRef.current.focus();

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNumber) {
      e.target.validity.valid && onChange(e.target.value);
    } else {
      onChange(e.target.value);
    }
  };

  return (
    <div className={`input-text-component ${showError && "error"}`}>
      <input
        ref={inputRef}
        value={value}
        onChange={onChangeText}
        placeholder=" "
        pattern={isNumber ? "[0-9]*" : undefined}
        type={isNumber ? "text" : type}
      />
      <label className="input-placeholder" onClick={setFocus}>
        {placeholder}
      </label>
      {showError && error && (
        <label className="input-text-caption">*{error}</label>
      )}
    </div>
  );
};

export { InputText };
