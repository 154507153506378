import { Route, RouteProps, Redirect } from "react-router-dom";
import { useStoreState } from "../store/typehook";

const ProtectedRoute = ({
  children: childrenComponent,
  ...rest
}: RouteProps) => {
  const isAuth = useStoreState((state) => state.auth.isAuth);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth ? (
          childrenComponent
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export { ProtectedRoute };
