import { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { Card, Button as CButton, Modal, Icon } from "../../components";
import { whatsappIcon } from "../../assets";
import { useStoreState, useStoreActions } from "../../store/typehook";

import "./styles.scss";

type Props = {
  navigate: (step: number) => void;
};

const Summary = ({ navigate }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const cart = useStoreState((state) => state.orders.shoppingCart);
  const showSuccess = useStoreState((state) => state.orders.showSuccess);
  const makeAnOrder = useStoreActions((actions) => actions.orders.makeAnOrder);
  const confirmOrderModalSeen = useStoreActions(
    (actions) => actions.orders.confirmOrderModalSeen
  );

  useEffect(() => {
    if (showSuccess && showSuccess !== undefined) {
      setShowModal(true);
    }
  }, [showSuccess]);

  const getDeliveryTitle = () =>
    cart?.needsDelivery ? "Envío a" : "Adquisición";

  const getDeliveryLabel = () =>
    cart?.needsDelivery
      ? `${cart.customer?.city}, ${cart.customer?.province}. ${cart.customer?.zipCode}`
      : "Retiro en Guanathletics";

  const getPaymentMethodLabel = () =>
    cart?.payment === "sinpe" ? "SINPE Móvil" : "Transferencia electrónica";

  const closeModal = () => {
    confirmOrderModalSeen();
    setShowModal(false);
    history.replace({ pathname: "/store" });
  };

  const placeOrder = () => {
    if (cart) {
      makeAnOrder(cart);
    }
  };

  const item = (title: string, value: string, step: number, border = false) => (
    <div
      className={`d-flex justify-content-between px-3 py-2 align-items-center flex-wrap ${
        border && "border-bottom"
      }`}
    >
      <div className="d-flex">
        <label className="me-2">{`${title}:`}</label>
        <label
          className="text-truncate"
          style={{ fontWeight: 600, maxWidth: 360 }}
        >
          {value}
        </label>
      </div>
      <Button
        onClick={() => navigate(step)}
        className="text-primary text-decoration-none"
        variant="link"
      >
        Cambiar
      </Button>
    </div>
  );

  return (
    <>
      <Row className="mt-5 justify-content-around pb-4 mb-5 order-summary-step">
        <Col xl="7" md="12">
          <Card flat>
            {item("Contacto", cart?.customer?.email || "", 1, true)}
            {item(getDeliveryTitle(), getDeliveryLabel(), 1, true)}
            {item("Método de pago", getPaymentMethodLabel(), 2)}
          </Card>

          <div className="mt-5">
            <div>
              <label className="attach-dark">
                Adjunte su comprobante{" "}
                <a
                  className="attach-link text-primary"
                  href="https://wa.link/wbaemi"
                  target="_blank"
                  rel="noreferrer"
                  role="button"
                >
                  aquí
                </a>
                .
              </label>
              <img className="attach-icon" src={whatsappIcon} alt="" />
            </div>
            <Card
              flat
              className="mt-3 d-flex flex-column align-items-center w-md-50 w-sm-100 p-4 order-recipe-contact"
            >
              <label className="mb-3">José Rodriguez Pérez</label>
              <label>Teléfono: 61061879</label>
            </Card>
          </div>
        </Col>
        <Col xl="4" md="12" className="mt-sm-5 mt-md-5 mt-xl-0">
          <div className="d-flex flex-column text-end order-total-summary">
            <label className="mb-3 semi-bold text-primary">
              Desgloce del pedido
            </label>
            <label className="mb-3">
              Subtotal: <label className="ms-3">₡{cart?.subtotal}</label>
            </label>
            <label className="mb-3">
              Envio:{" "}
              <label className="ms-3">
                ₡{cart?.needsDelivery ? cart?.deliveryFee || 2500 : 0}
              </label>
            </label>
            <div className="w-100 h-0 border-bottom mb-3" />
            <label className="semi-bold">
              {`Total (${cart?.products.length} producto(s)):`}{" "}
              <label className="ms-3 bold text-primary">
                ₡{Number(cart?.subtotal!) + (Number(cart?.needsDelivery) ? Number(cart?.deliveryFee) || 2500 : 0)}
              </label>
            </label>
          </div>
        </Col>
        <Col
          sm="12"
          className="d-flex justify-content-center order-summary-button"
        >
          <CButton fitContent onClick={() => placeOrder()}>
            Confirmar
          </CButton>
        </Col>
      </Row>
      <Modal
        show={showModal && Boolean(showSuccess)}
        onClose={() => closeModal()}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div style={{ marginBottom: 40 }}>
            <Icon icon="whatsapp" color="#3CB673" size={80} />
          </div>
          <label className="w-50 text-center mt-5 mb-5">{showSuccess}</label>

          <CButton fitContent onClick={() => closeModal()}>
            Continuar
          </CButton>
        </div>
      </Modal>
    </>
  );
};

export { Summary };
