import React from "react";

import { Container, Row, Col, Button } from "react-bootstrap";
import { iosIcon, androidIcon } from "../../assets";

import "./styles.scss";

const AppBanner = () => {
  return (
    <div className="banner-container">
      <Container fluid="sm">
        <div className="banner">
          <div className="background"></div>
          <div className="overlay">
            <Row sm="4">
              <Col sm="12" md="6">
                <Button variant="light" className="button-banner">
                  <a
                    href="https://guanathletics.wstudio.app/"
                    target="_blank"
                    rel="noreferrer"
                    role="button"
                  >
                    <img src={iosIcon} alt="ios" />
                    <label>ios</label>
                  </a>
                </Button>
              </Col>
              <Col sm="12" md="6">
                <Button variant="light" className="button-banner">
                  <a
                    href="https://guanathletics.wstudio.app/"
                    target="_blank"
                    rel="noreferrer"
                    role="button"
                  >
                    <img src={androidIcon} alt="ios" />
                    <label>android</label>
                  </a>
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AppBanner;
