import { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
  Spinner,
} from "react-bootstrap";

import { useStoreActions, useStoreState } from "../../store/typehook";

const Login = () => {
  const [user, setUser] = useState({ username: "", password: "" });
  const login = useStoreActions((actions) => actions.auth.login);
  const error = useStoreState((state) => state.auth.error);
  const isAuth = useStoreState((state) => state.auth.isAuth);
  const isLoading = useStoreState((state) => state.auth.loading);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (isAuth) {
      history.replace({ pathname: "/portal" });
    }
  }, [isAuth, location, history]);

  const submitLogin = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    login(user);
  };

  return (
    <Container fluid className="mt-5">
      <Row className="justify-content-center">
        <Col sm={12} md={6}>
          {error && (
            <Alert variant="danger" dismissible={false}>
              <Alert.Heading>Ups!</Alert.Heading>
              <p>{error}</p>
            </Alert>
          )}
          <Card>
            <Card.Body>
              <Form>
                <Form.Group className="mb-3" controlId="form-username">
                  <Form.Label>Usuario</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese el nombre de usuario"
                    value={user.username}
                    onChange={(value) =>
                      setUser({ ...user, username: value.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-5" controlId="form-password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Ingrese el password"
                    value={user.password}
                    onChange={(value) =>
                      setUser({ ...user, password: value.target.value })
                    }
                  />
                </Form.Group>
                {!isLoading ? (
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={(e) => submitLogin(e)}
                  >
                    Ingresar
                  </Button>
                ) : (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mr-2"
                    />
                    Autenticando...
                  </Button>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export { Login };
