import { FunctionComponent } from "react";

import "./styles.scss";

type Props = {
  color?: string;
  onClick: (value: string) => void;
  selected: boolean;
  type?: "color" | "size";
  value: string;
};

const RadioVariant: FunctionComponent<Props> = ({
  color,
  value,
  onClick,
  selected,
  type = "color",
  children,
}) => (
  <div
    style={{ backgroundColor: color }}
    onClick={() => onClick(value)}
    className={`radio-variant-component-container
      ${type}
      ${selected ? "selected" : ""}
    `}
  >
    {children && children}
  </div>
);

export { RadioVariant };
