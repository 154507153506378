import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

import { getEnvironment } from "./constants/env";

const { firebase } = getEnvironment();

const firebaseApp = initializeApp(firebase);
const storage = getStorage(firebaseApp);
const messaging = null;
// const messaging = isSupported ? getMessaging(firebaseApp) : null;

export { storage, firebaseApp, messaging };
