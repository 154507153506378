import React, { FunctionComponent } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useHistory } from "react-router-dom";

import { useStoreState, useStoreActions } from "../../store/typehook";
import { Button } from "../Button";
import { ProductCard } from "./ProductCard";
import { Radio } from "../Radio";
import "./styles.scss";

type Props = {
  show: boolean;
  onClose?: () => void;
};

const ShoppingCart: FunctionComponent<Props> = ({ show, onClose }) => {
  const cart = useStoreState((state) => state.orders.shoppingCart);
  const history = useHistory();
  const decreaseProduct = useStoreActions(
    (actions) => actions.orders.decreaseOrderProduct
  );
  const increaseProduct = useStoreActions(
    (actions) => actions.orders.increaseOrderProduct
  );
  const removeProduct = useStoreActions(
    (actions) => actions.orders.removeOrderProduct
  );
  const updateOrder = useStoreActions(
    (actions) => actions.orders.updateShoppingCart
  );

  const handleRadioChange = (value: string) => {
    console.log('value', value);
    updateOrder({ needsDelivery: value === "delivery" } as any);
  };

  const goToStore = () => {
    onClose && onClose();
    history.push({ pathname: "/store" });
  };

  const placeOrder = () => {
    if (cart) {
      onClose && onClose();
      updateOrder({
        ...cart,
      });
      history.push({ pathname: "/order" });
    }
  };

  return (
    <Offcanvas
      className="shopping-cart-component-container"
      show={show}
      onHide={onClose}
      placement="end"
    >
      <Offcanvas.Body style={{ padding: 0 }}>
        {!cart || (cart.products && cart.products.length === 0) ? (
          <div className="d-flex flex-column shadow-sm fallback">
            <label className="title">Tu carrito esta vacío!</label>
            <label className="mb-3">vuelve a la tienda</label>
            <Button fitContent horizontalPadding={40} onClick={goToStore}>
              ir a la tienda
            </Button>
          </div>
        ) : (
          <>
            <div className="d-flex flex-column shadow-sm order-info">
              <button
                type="button"
                className="btn-close p-3"
                aria-label="Close"
                onClick={() => onClose && onClose()}
              />
              <div className="d-flex flex-column justify-content-center align-items-center">
                <label className="subtotal-text">Subtotal</label>
                <label className="subtotal-price">₡{cart.subtotal}</label>

                <label className="text-center needs-delivery-text">
                  {`¿Cómo deseas obtener tu${
                    cart.products.length > 1 ? "s" : ""
                  } producto${cart.products.length > 1 ? "s" : ""}?`}
                </label>
                <div className="d-flex flex-column align-items-center">
                  <Radio
                    checked={!Boolean(cart.needsDelivery)}
                    label="En Guanathletics"
                    onChange={(e) => handleRadioChange(e)}
                    value="pick-up"
                  />
                  <Radio
                    checked={Boolean(cart.needsDelivery)}
                    label="Envío a domicilio"
                    onChange={(e) => handleRadioChange(e)}
                    value="delivery"
                  />
                </div>
                <Button
                  fitContent
                  horizontalPadding={40}
                  onClick={() => placeOrder()}
                >
                  Proceder al pago
                </Button>
              </div>
            </div>
            {cart.products &&
              cart.products.length > 0 &&
              cart.products.map((product, index) => (
                <ProductCard
                  key={`product-card-item-${index}`}
                  img={product.img}
                  name={product.name}
                  price={product.total}
                  quantity={product.quantity}
                  onDecrease={() => decreaseProduct(product.id!)}
                  onIncrease={() => increaseProduct(product.id!)}
                  onDelete={() => removeProduct(product.id!)}
                />
              ))}
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export { ShoppingCart };
