import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import "./styles.scss";

const Classes = () => {
  return (
    <div className="view-container">
      <Container fluid="sm">
        <Row className="justify-content-md-center">
          <Col md="12" sm="6">
            <Row>
              <Col className="img-holder" md="6" sm="12">
                <img
                  className="img"
                  alt="schedule"
                  src="https://firebasestorage.googleapis.com/v0/b/guanathletics.appspot.com/o/schedule.jpeg?alt=media&token=3bf02c47-ae04-4e7a-b5fb-7dd184b74428"
                />
              </Col>
              <Col className="img-holder" md="6" sm="12">
                <img
                  className="img"
                  alt="schedule"
                  src="https://firebasestorage.googleapis.com/v0/b/guanathletics.appspot.com/o/classes-gnt.jpeg?alt=media&token=32c2c28e-1e89-4dc5-b5a9-9e7aec348185"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Classes;
