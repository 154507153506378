import { action, thunk, actionOn } from "easy-peasy";

import { Order, OrdersModel } from "./model";
import {
  getOrder,
  getOrders,
  updateOrder,
  updateOrderTrackingId,
  returnOrder,
  completeOrder,
  makeAnOrder,
} from "../../api/orders";

const orders: OrdersModel = {
  data: [],
  loading: false,
  error: undefined,
  removeCurrentOrder: action((state) => {
    state.currentOrder = undefined;
    state.showSuccess = undefined;
  }),
  setCurrentOrder: action((state, payload) => {
    state.showSuccess = undefined;
    state.currentOrder = payload;
  }),
  fetchOrders: action((state) => {
    state.loading = true;
    state.error = undefined;
  }),
  fetchOrdersSuccess: action((state, payload) => {
    state.loading = false;
    state.error = undefined;
    state.data = payload;
  }),
  fetchOrdersFailure: action((state, payload) => {
    state.loading = false;
    state.error = payload;
  }),
  fetchOrder: action((state) => {
    state.loading = true;
    state.error = undefined;
  }),
  fetchOrderSuccess: action((state, payload) => {
    state.loading = false;
    state.error = undefined;
    state.currentOrder = payload;
  }),
  fetchOrderFailure: action((state, payload) => {
    state.loading = false;
    state.error = payload;
  }),
  getOrders: thunk(async (actions) => {
    actions.fetchOrders();
    const response = await getOrders<Order[]>();

    if (response.data) {
      actions.fetchOrdersSuccess(response.data);
    } else {
      actions.fetchOrdersFailure(response.errorMessage || "unknow error");
    }
  }),
  updateOrder: action((state) => {
    state.loading = true;
    state.error = undefined;
  }),
  updateOrderSuccess: action((state) => {
    state.loading = false;
    state.error = undefined;
    state.showSuccess = "Se actualizó la orden perfectamente!";
  }),
  updateOrderFailure: action((state, payload) => {
    state.loading = false;
    state.error = payload;
  }),
  updateOrderTrackingIdSuccess: action((state, payload) => {
    state.loading = false;
    state.error = undefined;
    state.showSuccess = "Se actualizó el número de rastreo!";
    state.currentOrder = { ...state.currentOrder!, trackingId: payload };
  }),
  returnOrderSuccess: action((state) => {
    state.loading = false;
    state.error = undefined;
    state.showSuccess = "La orden ha sido retornada!";
    state.currentOrder = { ...state.currentOrder!, status: "returned" };
  }),
  updateOrderCompleteSuccess: action((state) => {
    state.loading = false;
    state.error = undefined;
    state.showSuccess = "La orden ha sido completada!";
    state.currentOrder = { ...state.currentOrder!, status: "completed" };
  }),
  resetCurrentOrder: action((state) => {
    state.loading = false;
    state.error = undefined;
    state.showSuccess = undefined;
  }),
  addProductToCart: action((state, payload) => {
    if (!state.shoppingCart) {
      state.shoppingCart = {
        needsDelivery: false,
        total: 0,
        subtotal: payload.total,
        deliveryFee: 2500,
        products: [payload],
        // TODO: add customer if already exist in localstorage,
      };
    } else {
      state.shoppingCart = {
        ...state.shoppingCart,
        subtotal: Number(state.shoppingCart.subtotal) + Number(payload.total),
        products: [...state.shoppingCart.products, payload],
      };
    }
  }),
  removeOrderProduct: action((state, payload) => {
    if (state.shoppingCart && state.shoppingCart.products.length > 0) {
      const product = state.shoppingCart.products.find(product => product.id === payload);

      if (product) {
        state.shoppingCart.subtotal = Number(state.shoppingCart.subtotal) - (Number(product.total) * Number(product.quantity));

        state.shoppingCart.products = state.shoppingCart.products.filter(
          (product) => product.id !== payload
        );
      }
    }
  }),
  decreaseOrderProduct: action((state, payload) => {
    if (state.shoppingCart && state.shoppingCart.products.length > 0) {
      const product = state.shoppingCart.products.find(product => product.id === payload);

      if (product) {
        state.shoppingCart.subtotal = Number(state.shoppingCart.subtotal) - Number(product.total);
        state.shoppingCart.products = state.shoppingCart.products.map(
          (product) => {
            if (product.id === payload) {
              product.quantity = product.quantity - 1;

              return product;
            }

            return product;
          }
        );
      }
    }
  }),
  onDecreaseOrderProduct: actionOn(
    (actions) => actions.decreaseOrderProduct,
    (state) => {
      if (
        state.shoppingCart &&
        state.shoppingCart.products &&
        state.shoppingCart.products.length > 0
      ) {
        state.shoppingCart.products = state.shoppingCart.products.filter(
          (product) => product.quantity > 0
        );
      }
    }
  ),
  increaseOrderProduct: action((state, payload) => {
    if (state.shoppingCart && state.shoppingCart.products.length > 0) {
      const product = state.shoppingCart.products.find(product => product.id === payload);

      if (product) {
        state.shoppingCart.subtotal = Number(state.shoppingCart.subtotal) + Number(product.subtotal);
        state.shoppingCart.products = state.shoppingCart.products.map(
          (product) => {
            if (product.id === payload) {
              product.quantity = product.quantity + 1;

              return product;
            }

            return product;
          }
        );
      }
    }
  }),
  updateShoppingCart: action((state, payload) => {
    if (state.shoppingCart) {
      state.shoppingCart = {
        ...state.shoppingCart,
        ...payload,
      };
    }
  }),
  updateCustomer: action((state, payload) => {
    if (state.shoppingCart) {
      state.shoppingCart.customer = {
        ...state.shoppingCart.customer,
        ...payload,
      };
    }
  }),
  setOrderPaymentMethod: action((state, payload) => {
    if (state.shoppingCart) {
      state.shoppingCart.payment = payload;
    }
  }),
  makeAnOrderPending: action((state, payload) => {
    state.loading = true;
    state.error = undefined;
    state.showSuccess = undefined;
  }),
  makeAnOrderSuccess: action((state, payload) => {
    state.loading = false;
    state.error = undefined;
    state.showSuccess =
      "La orden esta siendo procesada, te enviaremos un correo de confirmación!";
  }),
  makeAnOrderFailure: action((state, payload) => {
    state.loading = false;
    state.error = payload;
    state.showSuccess = undefined;
  }),
  confirmOrderModalSeen: action((state) => {
    state.loading = false;
    state.error = undefined;
    state.showSuccess = undefined;
    state.shoppingCart = undefined;
  }),
  putOrder: thunk(async (actions, payload) => {
    actions.updateOrder();
    const response = await updateOrder(payload.id, payload);

    if (response.data) {
      actions.updateOrderSuccess();
    } else {
      actions.updateOrderFailure(response.errorMessage || "unknow error");
    }
  }),
  returnOrder: thunk(async (actions, payload) => {
    actions.updateOrder();
    const response = await returnOrder(payload);

    if (response.data) {
      actions.returnOrderSuccess();
    } else {
      actions.updateOrderFailure(response.errorMessage || "unknow error");
    }
  }),
  getOrder: thunk(async (actions, payload) => {
    actions.fetchOrder();
    const response = await getOrder(payload);

    if (response.data) {
      actions.fetchOrderSuccess(response.data);
    } else {
      actions.fetchOrderFailure(response.errorMessage || "unknow error");
    }
  }),
  putOrderTrackingId: thunk(async (actions, payload) => {
    actions.updateOrder();
    const response = await updateOrderTrackingId(
      payload.orderId,
      payload.trackingId
    );

    if (response.data) {
      actions.updateOrderTrackingIdSuccess(payload.trackingId);
    } else {
      actions.updateOrderFailure(response.errorMessage || "unknow error");
    }
  }),
  putOrderComplete: thunk(async (actions, payload) => {
    actions.updateOrder();
    const response = await completeOrder(payload);

    if (response.data) {
      actions.updateOrderCompleteSuccess();
    } else {
      actions.updateOrderFailure(response.errorMessage || "unknow error");
    }
  }),
  makeAnOrder: thunk(async (actions, payload) => {
    actions.makeAnOrderPending();
    const response = await makeAnOrder(payload);

    if (response.data) {
      actions.makeAnOrderSuccess();
    } else {
      actions.makeAnOrderFailure(response.errorMessage || "unknow error");
    }
  }),
};

export { orders };
