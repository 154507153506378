import { useStoreActions } from "../../store/typehook";
import { cartIcon } from "../../assets";
import { Icon } from "../Icon";

import "./styles.scss";

type Props = {
  hideCart?: boolean;
  route?: string;
};

const NavBar = ({ hideCart, route }: Props) => {
  const toggleDrawer = useStoreActions(
    (actions) => actions.global.toggleDrawer
  );
  const toggleShoppingCart = useStoreActions(
    (actions) => actions.global.toggleShoppingCart
  );

  return (
    <div className="d-flex flex-row justify-content-center custom-nav-holder-container align-items-center">
      <div className="nav-bar-actions d-flex justify-content-between px-3 align-items-center">
        <div className="d-flex align-items-center" onClick={() => toggleDrawer()}>
          <div className="menu-toggle">
            <Icon icon="menu" size={30} color="#BCD530" />
          </div>
          {route && <label className="route-name ms-2">{route}</label>}
        </div>
        <div>
          {!hideCart && (
            <img
              onClick={() => toggleShoppingCart()}
              className="cart-icon"
              src={cartIcon}
              alt=""
            />
          )}
        </div>
      </div>
      <label className="title">Guanathletics</label>
    </div>
  );
};

export default NavBar;
