import React, { useEffect } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Notification, ShoppingCart, NavDrawer } from "../../components";
import { NotificationRef } from "../../utils/notification/notificationRef";
import { NotificationService } from "../../services/notification";
import { useStoreState, useStoreActions } from '../../store/typehook';
import {
  AppRouter,
  AuthRouter,
  ProtectedRoute,
  PortalRouter,
} from "../../router";

import "./styles.scss";

const App: React.FunctionComponent = () => {
  const showShoppingCart = useStoreState((state) => state.global.showShoppingCart);
  const toggleShoppingCart = useStoreActions((actions) => actions.global.toggleShoppingCart);
  const showDrawer = useStoreState((state) => state.global.showDrawer);
  const toggleDrawer = useStoreActions((actions) => actions.global.toggleDrawer);

  useEffect(() => {
    NotificationService.init();
  }, []);

  return (
    <div className="app">
      <Router>
        <Switch>
          <Route path="/auth">
            <AuthRouter />
          </Route>
          <ProtectedRoute path="/portal">
            <PortalRouter />
          </ProtectedRoute>
          <Route path="/">
            <AppRouter />
          </Route>
        </Switch>
        <Notification
          ref={(ref) => NotificationRef.setNotificationRef(ref as any)}
        />
        <ShoppingCart show={showShoppingCart} onClose={() => toggleShoppingCart()} />
        <NavDrawer show={showDrawer} onClose={() => toggleDrawer()} />
      </Router>
    </div>
  );
};

export default App;
