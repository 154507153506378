import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  ListGroup,
  Dropdown,
  Card,
  Accordion,
  ProgressBar,
  Alert,
  Modal,
} from "react-bootstrap";

import { Uploader } from "../../../../hooks/useUploader";
import { useStoreActions, useStoreState } from "../../../../store/typehook";
import { Showcase } from "../../../../components";

interface Variants {
  [key: string]: {
    imgs: string[] | [];
    value: string;
  };
}

interface ProductPayloadInitialState {
  id?: string;
  name: string;
  price: number;
  description?: string;
  available: number;
  stockMaintenance: boolean;
  isInStock: boolean;
  imgs: string[];
  variants?: Variants;
  sizes?: string | string[];
  category?: string;
  status: "draft" | "published";
  productType: "simple" | "variant";
  createdAt?: number;
  thumbnail: string;
}

const productPayloadInitialState: ProductPayloadInitialState = {
  name: "",
  price: 0,
  description: "",
  available: 0,
  stockMaintenance: false,
  isInStock: true,
  imgs: [],
  category: "",
  status: "draft",
  productType: "simple",
  thumbnail: "",
};

const ManageProduct = () => {
  const updateProduct = useStoreActions(
    (actions) => actions.products.putProduct
  );
  const addProduct = useStoreActions((actions) => actions.products.postProduct);
  const currentProduct = useStoreState(
    (state) => state.products.portal.currentProduct
  );
  const deleteProduct = useStoreActions(
    (actions) => actions.products.deleteProduct
  );
  const showSuccess = useStoreState((state) => state.products.showSuccess);
  const [error, setError] = useState("");
  const [colorAttr, setColorAttr] = useState({ color: "", value: "" });
  const [showModal, setShowModal] = useState(false);
  const {
    progress,
    handleFileChange,
    error: uploadError,
  } = Uploader("products");
  const [productPayload, setProductPayload] = useState(
    productPayloadInitialState
  );

  const handleInputChange = (
    key: keyof ProductPayloadInitialState,
    value: string | boolean | string[]
  ) => {
    setError("");
    setProductPayload((product) => ({
      ...product,
      [key]: value,
    }));
  };

  const addVariantImgs = (variantKey: string, urls: string[]) => {
    setProductPayload((product) => ({
      ...product,
      variants: {
        ...product.variants,
        [variantKey]: {
          ...product.variants![variantKey],
          imgs: urls,
        },
      },
    }));
  };

  const addColorVariant = () => {
    if (colorAttr.color && colorAttr.value) {
      setProductPayload((product) => ({
        ...product,
        variants: {
          ...product.variants,
          [colorAttr.color]: { imgs: [], value: colorAttr.value },
        },
      }));
      setColorAttr({ color: "", value: "" });
    }
  };

  const submitProduct = (publish = false) => {
    if (
      !productPayload.name ||
      !productPayload.price ||
      productPayload.price <= 0 ||
      !productPayload.thumbnail ||
      productPayload.thumbnail === '' ||
      (productPayload.productType === "simple" &&
        productPayload.imgs.length === 0) ||
      (productPayload.productType === "variant" && !productPayload.variants) ||
      !productPayload.description
    ) {
      setError("Por favor ingrese los campos requeridos marcados con un *");
    } else {
      const stockMaintenance =
        productPayload.productType === "variant"
          ? false
          : productPayload.stockMaintenance;
      const status = publish ? "published" : productPayload.status;

      if (productPayload.id) {
        updateProduct({
          ...productPayload,
          stockMaintenance,
          status,
        });
      } else {
        addProduct({
          ...productPayload,
          stockMaintenance,
          status,
        });
      }
    }
  };

  const handleDeleteConfirm = () => {
    if (productPayload.id) {
      deleteProduct(productPayload.id);
      setShowModal(false);
      setProductPayload(productPayloadInitialState);
    }
  };

  const handleDelete = () => {
    if (productPayload.id) {
      setShowModal(true);
    } else {
      setProductPayload(productPayloadInitialState);
    }
  };

  useEffect(() => {
    if (currentProduct) {
      setProductPayload(currentProduct);
    }
  }, [currentProduct]);

  return (
    <Container className="pb-5">
      {showSuccess && (
        <Row>
          <Col>
            <Alert variant="success">
              <Alert.Heading>Perfecto! ✅</Alert.Heading>
              <p>{showSuccess}</p>
            </Alert>
          </Col>
        </Row>
      )}
      {error && (
        <Row>
          <Col>
            <Alert variant="danger">
              <Alert.Heading>Ops! Campos requeridos</Alert.Heading>
              <p>{error}</p>
            </Alert>
          </Col>
        </Row>
      )}
      {uploadError && (
        <Row>
          <Col>
            <Alert variant="danger">
              <Alert.Heading>Ops!</Alert.Heading>
              <p>{uploadError}</p>
            </Alert>
          </Col>
        </Row>
      )}
      <Row className="mb-3">
        <Col sm="9">
          <Card>
            <Card.Body>
              <Form noValidate>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <h4 className="mb-4 mt-2">Información general</h4>
                    <Dropdown className="mb-3">
                      <Dropdown.Toggle id="productType">
                        {`Tipo de producto: ${
                          productPayload.productType === "simple"
                            ? "Simple"
                            : "Variantes"
                        }`}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            handleInputChange("productType", "simple")
                          }
                        >
                          Sencillo
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handleInputChange("productType", "variant")
                          }
                        >
                          Variantes
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {productPayload.productType === "variant" && (
                      <div className="mb-3">
                        <Form.Text className="text-muted">
                          Nota: Los productos variantes no manejan stock de
                          momento.
                        </Form.Text>
                      </div>
                    )}

                    <Form.Group className="mb-3" controlId="formProductName">
                      <Form.Label>Nombre del producto *</Form.Label>
                      <Form.Control
                        type="text"
                        value={productPayload.name}
                        onChange={(e) =>
                          handleInputChange("name", e.target.value)
                        }
                        placeholder="ejemplo: Camisa sin mangas"
                      />
                      <Form.Text className="text-muted">
                        Este es el nombre que va a aparecer en la lista de
                        productos para el cliente.
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formProductPrice">
                      <Form.Label>
                        Precio del producto por unidad en colones *
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend">
                          ₡
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          aria-describedby="inputGroupPrepend"
                          value={productPayload.price}
                          onChange={(e) =>
                            handleInputChange("price", e.target.value)
                          }
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="formProductDescription"
                    >
                      <Form.Label>Descripción del producto *</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={productPayload.description}
                        onChange={(e) =>
                          handleInputChange("description", e.target.value)
                        }
                      />
                      <Form.Text className="text-muted">
                        Esta descripción es para darle una breve informacion del
                        producto al cliente.
                      </Form.Text>
                    </Form.Group>
                  </ListGroup.Item>
                  {productPayload.productType === "simple" && (
                    <ListGroup.Item>
                      <h4 className="mb-4 mt-2">Inventario</h4>
                      <Row className="mb-3">
                        <Col sm="5">
                          <Form.Text>Mantenimiento para el stock?</Form.Text>
                        </Col>
                        <Col sm="7">
                          <Form.Check
                            type="checkbox"
                            label="Habilitar mantenimiento para el producto"
                            feedbackTooltip
                          >
                            <Form.Check.Input
                              type="checkbox"
                              checked={productPayload.stockMaintenance}
                              onChange={(e) =>
                                handleInputChange(
                                  "stockMaintenance",
                                  e.target.checked
                                )
                              }
                            />
                            <Form.Check.Label>
                              <Form.Text>
                                Habilitar mantenimiento para el producto
                              </Form.Text>
                            </Form.Check.Label>
                            <Form.Control.Feedback>
                              No se generara un número de productos en stock
                            </Form.Control.Feedback>
                          </Form.Check>
                        </Col>
                      </Row>
                      {productPayload.stockMaintenance && (
                        <Row className="mb-3">
                          <Col sm="5" className="d-flex align-items-center">
                            <Form.Text>Disponibilidad de inventario</Form.Text>
                          </Col>
                          <Col sm="7">
                            <Form.Group controlId="formProductAvailability">
                              <Form.Control
                                type="number"
                                value={productPayload.available}
                                onChange={(e) =>
                                  handleInputChange("available", e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                      <Row className="mb-2">
                        <Col sm="5">
                          <Form.Text>Estado de la disponibilidad</Form.Text>
                        </Col>
                        <Col sm="7">
                          <Form.Check type="checkbox" label="En stock?">
                            <Form.Check.Input
                              type="checkbox"
                              checked={productPayload.isInStock}
                              onChange={(e) =>
                                handleInputChange("isInStock", e.target.checked)
                              }
                            />
                            <Form.Check.Label>
                              <Form.Text>En stock?</Form.Text>
                            </Form.Check.Label>
                          </Form.Check>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}
                  <ListGroup.Item>
                    <h4 className="mb-4 mt-2">Imagenes del producto</h4>
                    {progress > 0 && (
                      <ProgressBar className="mb-2" now={progress} />
                    )}
                    <Form.Group className="mb-3" controlId="formFileUnique">
                      {productPayload.thumbnail && (
                        <Showcase data={[productPayload.thumbnail]} />
                      )}
                      <Form.Label>
                        Seleccione el thumbnail del producto *
                      </Form.Label>
                      <Form.Control
                        onChange={(e) =>
                          handleFileChange(e as any, (urls) =>
                            handleInputChange("thumbnail", urls[0])
                          )
                        }
                        type="file"
                      />
                      <Form.Text className="mt-2" muted>
                        Esta es la imagen que le aparecerá en la lista de
                        productos al cliente como representación de este
                        producto.
                      </Form.Text>
                    </Form.Group>
                    {productPayload.productType === "simple" && (
                      <Form.Group className="mb-3" controlId="formFileMultiple">
                        {productPayload.imgs.length > 0 && (
                          <Showcase data={productPayload.imgs} />
                        )}
                        <Form.Label>
                          Seleccione las imagenés para el detalle del producto *
                        </Form.Label>
                        <Form.Control
                          onChange={(e) =>
                            handleFileChange(e as any, (urls) =>
                              handleInputChange("imgs", urls)
                            )
                          }
                          type="file"
                          multiple
                        />
                        <Form.Text className="mt-2" muted>
                          Estas son las imágenes que aparecerán como un carrusel
                          en el detalle del producto.
                        </Form.Text>
                      </Form.Group>
                    )}
                  </ListGroup.Item>
                  {productPayload.productType === "variant" && (
                    <ListGroup.Item>
                      <h4 className="mb-4 mt-2">Variantes</h4>
                      <h5 className="mb-4">Colores</h5>
                      <div className="mb-3 d-flex align-items-end flex-row">
                        <Form.Group
                          className="me-3"
                          controlId="formFileColorNameVariant"
                        >
                          <Form.Label>Nombre del color</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ejemplo: Negro"
                            value={colorAttr.color}
                            onChange={(e) =>
                              setColorAttr({
                                ...colorAttr,
                                color: e.target.value,
                              })
                            }
                          />
                        </Form.Group>

                        <Form.Group className="me-4">
                          <Form.Label htmlFor="formFileColor">Color</Form.Label>
                          <Form.Control
                            type="color"
                            id="formFileColor"
                            title="Escoja un color"
                            value={colorAttr.value}
                            onChange={(e) =>
                              setColorAttr({
                                ...colorAttr,
                                value: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <Button
                          disabled={!colorAttr.color && !colorAttr.value}
                          onClick={addColorVariant}
                        >
                          Agregar variante
                        </Button>
                      </div>
                      {productPayload.variants && (
                        <Accordion
                          defaultActiveKey="0"
                          className="mt-3 mb-3"
                          flush
                        >
                          {Object.keys(productPayload.variants).map(
                            (variantKey, i) => (
                              <Accordion.Item key={i} eventKey={`${i}`}>
                                <Accordion.Header>
                                  {variantKey}
                                </Accordion.Header>
                                <Accordion.Body>
                                  {productPayload.variants &&
                                    productPayload.variants[variantKey] &&
                                    productPayload.variants[variantKey].imgs
                                      .length > 0 && (
                                      <Showcase
                                        data={
                                          productPayload.variants[variantKey]
                                            .imgs
                                        }
                                      />
                                    )}
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formFileMultiple"
                                  >
                                    <Form.Label>
                                      Seleccione las imágenes para esta variante
                                    </Form.Label>
                                    <Form.Control
                                      onChange={(e) =>
                                        handleFileChange(e as any, (urls) =>
                                          addVariantImgs(variantKey, urls)
                                        )
                                      }
                                      size="sm"
                                      type="file"
                                      multiple
                                    />
                                  </Form.Group>
                                </Accordion.Body>
                              </Accordion.Item>
                            )
                          )}
                        </Accordion>
                      )}
                      <h5 className="mb-4">Tamaños</h5>
                      <Form.Group
                        className="mb-3"
                        controlId="formProductDescription"
                      >
                        <Form.Label>
                          Ingrese las opciones de tamaños divididos el caratér
                          "|"
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={productPayload.sizes}
                          onChange={(e) =>
                            handleInputChange("sizes", e.target.value)
                          }
                          placeholder={"M | S | L"}
                        />
                      </Form.Group>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="3">
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Producto</Card.Title>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <Row>
                  <Col sm="12" className="d-flex justify-content-end">
                    <Button
                      onClick={() => submitProduct()}
                      variant="outline-secondary"
                      className="mb-3"
                    >
                      Guardar borrador
                    </Button>
                  </Col>
                  <Col sm="12">
                    <Form.Text className="mb-3">{`Estado: ${
                      productPayload.status === "draft" ? "borrador" : "público"
                    }`}</Form.Text>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="mt-2">
                  <Col sm="6">
                    <Button
                      variant="link"
                      className="text-danger text-start text-decoration-underline"
                      onClick={() => handleDelete()}
                    >
                      Eliminar
                    </Button>
                  </Col>
                  <Col sm="6">
                    <Button onClick={() => submitProduct(true)}>
                      Publicar
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card>

          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Categorias</Card.Title>
              <Row className="justify-content-start mt-3">
                <Col sm="6">
                  <Form.Check
                    type="radio"
                    id="category-radio-1"
                    label="Hombre"
                    checked={productPayload.category === "man"}
                    onChange={() => handleInputChange("category", "man")}
                  />

                  <Form.Check
                    type="radio"
                    id="category-radio-2"
                    label="Mujer"
                    checked={productPayload.category === "woman"}
                    onChange={() => handleInputChange("category", "woman")}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Borrar producto?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Estas seguro que quieres eliminar este producto?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export { ManageProduct };
