import { FunctionComponent } from "react";

import "./styles.scss";

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  type?: "primary" | "secondary";
  className?: string;
  style?: React.CSSProperties;
  fitContent?: boolean;
  horizontalPadding?: number;
};

const Button: FunctionComponent<Props> = ({
  children,
  onClick = () => {},
  disabled = false,
  type = "primary",
  className = "",
  style,
  fitContent = false,
  horizontalPadding = 56,
}) => {
  const fitContentStyle = fitContent
    ? { width: "fit-content", padding: `12px ${horizontalPadding}px` }
    : { width: "100%" };

  return (
    <button
      className={`
      button-component-container
      ${disabled ? "disabled" : ""}
      ${type === "primary" ? "primary" : ""}
      ${className}
      `}
      style={{ ...fitContentStyle, ...style }}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export { Button };
