import { Order, UserOrder } from "../store/orders/model";
import { privateRequest, request } from "./";

export const getOrders = async <T>() => {
  return privateRequest<T>("GET", "order");
};

export const getOrder = async <T>(orderId: string) => {
  return privateRequest<T>("GET", `order/${orderId}`);
};

export const updateOrder = async <T>(orderId: string, payload: Order) => {
  return privateRequest<T>("PUT", `order/${orderId}`, payload);
};

export const returnOrder = async <T>(orderId: string) => {
  return privateRequest<T>("PUT", `order/return/${orderId}`);
};

export const updateOrderTrackingId = async <T>(
  orderId: string,
  trackingId: string
) => {
  return privateRequest<T>("PUT", `order/track/${orderId}`, { trackingId });
};

export const completeOrder = async <T>(orderId: string) => {
  return privateRequest<T>("PUT", `order/complete/${orderId}`);
};

export const makeAnOrder = async <T>(order: UserOrder) => {
  return request<T>("POST", "order", order);
};
