import { useState } from "react";
import { Row, Container, Breadcrumb, Col, Spinner } from "react-bootstrap";

import { Accordion, Image } from "../../components";
import { UserProduct } from "../../store/products/model";
import { useStoreState } from "../../store/typehook";
import { ContactInfo } from "./ContactInfo";
import { PaymentMethod } from "./PaymentMethod";
import { Summary } from "./Summary";

import "./styles.scss";

const Order = () => {
  const [, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(1);
  const cart = useStoreState((state) => state.orders.shoppingCart);
  const isLoading = useStoreState((state) => state.orders.loading);

  const orderItemToggle = () => (
    <div className="order-toggle">
      <label className="title">Resumen del pedido</label>
      <label className="price">₡{cart?.subtotal}</label>
    </div>
  );

  const orderItem = (product: UserProduct) => (
    <div key={product.id} className="my-4 p-2 p-xl-4 d-flex w-100 order-item">
      <Image className="m-0" type="small" source={product.img} />
      <div className="d-flex justify-content-between align-items-center ms-3 ms-xl-5 order-info">
        <div className="d-flex flex-column">
          <label className="name">{product.name}</label>
          <label className="description">{product.description}</label>
        </div>
        <label className="price">₡{product.subtotal}</label>
      </div>
    </div>
  );

  const navigateNextScreen = (step: number) => {
    if (progress >= step || (cart && cart.customer && cart.payment)) {
      setStep(step);
    }
  };

  const onStepCompleteCb = (nextStep: number) => {
    setStep(nextStep);
    setProgress(nextStep);
  };

  if (!cart) {
    // add fallback view
    return null;
  }

  return (
    <div className="pb-5 order-view">
      <Accordion
        onChange={(e, isActive) => setIsOpen(isActive)}
        data={[
          {
            toggle: orderItemToggle(),
            eventKey: "0",
            component: (
              <div>
                <div className="py-3 px-5 d-flex flex-column products">
                  {cart.products.map(orderItem)}
                </div>
                <div className="mx-5 py-3 px-4 d-flex justify-content-end order-summary">
                  <label className="text">{`Subtotal (${cart.products.length} productos):`}</label>
                  <label className="ms-3 price">₡{cart.subtotal}</label>
                </div>
              </div>
            ),
          },
        ]}
      />
      {isLoading ? (
        <Row className="justify-content-center my-5 py-5">
          <Spinner animation="grow" variant="primary" />
        </Row>
      ) : (
        <Container fluid="sm mt-5 pb-3 mb-5">
          <Row className="justify-content-md-center">
            <Breadcrumb>
              <Breadcrumb.Item
                onClick={() => navigateNextScreen(1)}
                active={step === 1}
              >
                Información de contacto
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => navigateNextScreen(2)}
                active={step === 2}
              >
                Método de pago
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => navigateNextScreen(3)}
                active={step === 3}
              >
                Confirmación
              </Breadcrumb.Item>
            </Breadcrumb>
            <Col sm="10">
              {step === 1 && (
                <ContactInfo onStepComplete={() => onStepCompleteCb(2)} />
              )}
              {step === 2 && (
                <PaymentMethod onStepComplete={() => onStepCompleteCb(3)} />
              )}
              {step === 3 && <Summary navigate={(nStep) => setStep(nStep)} />}
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export { Order };
