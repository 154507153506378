import { getToken, onMessage, MessagePayload } from "firebase/messaging";

import { messaging } from "../../firebase.config";
import { getEnvironment } from "../../constants/env";
import { NotificationRef } from "../../utils/notification/notificationRef";

const { vapidKey } = getEnvironment();

export const getFCMToken = async () => {
  try {
    if (messaging) {
      const res = await getToken(messaging, { vapidKey });

      return res;
    }
  } catch (error) {
    console.log("someting happend", error);
  }
};

class NotificationClass {
  private setNotificationHandler(payload: MessagePayload) {
    const ref = NotificationRef.getNotificationRef();

    if (ref) {
      const data = payload.data;
      ref.pushNotification(data);
    }
  }

  init() {
    if (messaging) {
      onMessage(messaging, this.setNotificationHandler);
    }
  }
}

const NotificationService = new NotificationClass();

export { NotificationService };
