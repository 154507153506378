import { action, thunk } from "easy-peasy";

import { AuthModel, User } from "./model";
import { login, updateUser } from "../../api/auth";
import { getFCMToken } from "../../services/notification";

const auth: AuthModel = {
  user: undefined,
  loading: false,
  error: undefined,
  isAuth: false,
  logout: action((state) => {
    state.user = undefined;
    state.isAuth = false;
    localStorage.removeItem("token");
  }),
  loginPending: action((state) => {
    state.loading = true;
    state.error = undefined;
  }),
  loginSuccess: action((state, payload) => {
    state.user = payload;
    state.error = undefined;
    state.loading = false;
    state.isAuth = true;
  }),
  loginFailure: action((state, payload) => {
    state.error = payload;
    state.loading = false;
    state.isAuth = false;
  }),
  login: thunk(async (actions, payload) => {
    actions.loginPending();
    const response = await login<User>(payload);

    if (response.data && !response.errorMessage) {
      const token = response.data.access_token;
      localStorage.setItem("token", token);

      actions.updateFCMToken();

      actions.loginSuccess(response.data);
    } else if (response.errorMessage) {
      actions.loginFailure(response.errorMessage);
    }
  }),
  updateFCMToken: thunk(async () => {
    const fcmToken = await getFCMToken();

    if (fcmToken) {
      updateUser({ fcmToken });
    }
  }),
};

export { auth };
