import "./styles.scss";

type Props = {
  data: string[];
  size?: number;
  gap?: number;
};

const Showcase = ({ data, size = 100, gap = 55 }: Props) => (
  <div
    className="position-relative w-100 showcase mb-3"
    style={{ height: size }}
  >
    {data.map((img, i) => (
      <div
        key={`showcase-key-${i}`}
        className="position-absolute top-0 bottom-0 shadow item border border-white"
        style={{
          left: gap * i,
          backgroundImage: `url(${img})`,
          height: size,
          width: size,
        }}
      />
    ))}
  </div>
);

export { Showcase };
