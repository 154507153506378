import { useState, useEffect } from "react";
import { Row, Col, DropdownButton, Dropdown } from "react-bootstrap";

import { InputText, Button, TextArea, Radio } from "../../components";
import { userInfoScheme, userChipmentScheme } from "./schemes/userInfo.scheme";
import { useStoreState, useStoreActions } from "../../store/typehook";
import { STATES } from "../../constants/states";
import { Customer } from "../../store/orders/model";

type Props = {
  onStepComplete: () => void;
};

type Form = {
  name?: string;
  email?: string;
  phone?: string;
  lastname?: string;
  confirmEmail?: string;
  address?: string;
  zipCode?: string;
  province?: string;
  city?: string;
  district?: string;
  documentId?: string;
};

type FormErrors = {
  [key in Form as string]: {
    error: string;
  };
};

const initialFormState: Form = {
  name: undefined,
  email: undefined,
  phone: undefined,
  lastname: undefined,
  confirmEmail: undefined,
};

const ContactInfo = ({ onStepComplete }: Props) => {
  const [form, setForm] = useState<Form>(initialFormState);
  const [errors, setErrors] = useState<FormErrors>({});
  const [delivery, setDelivery] = useState('');
  const cart = useStoreState((state) => state.orders.shoppingCart);
  const updateCustomer = useStoreActions(
    (actions) => actions.orders.updateCustomer
  );
  const updateShoppingCart = useStoreActions(
    (actions) => actions.orders.updateShoppingCart
  );
  const setPreviousCustomer = useStoreActions(
    (actions) => actions.global.setPreviousCustomer
  );

  useEffect(() => {
    const deliveryValue = cart && cart.needsDelivery ? 'delivery' : 'pickup';
    setDelivery(deliveryValue);

    if (cart && cart.customer) {
      setForm({ ...cart.customer });
    }
  }, [cart]);

  const clearErrors = (field?: keyof Form) => {
    if (field) {
      setErrors((prev) => {
        const errorsUpdated = { ...prev };
        delete errorsUpdated[field];
        return errorsUpdated;
      });
    } else {
      setErrors({});
    }
  };

  const updateFormField = (key: keyof Form, value: string) => {
    clearErrors(key);
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const extractErrors = (innerErrors: { path: string; errors: string[] }[]) => {
    let errors = {};
    innerErrors.map(
      (err) =>
        (errors = {
          ...errors,
          ...{ [err.path]: { error: err.errors[0] } },
        })
    );

    setErrors(errors);
  };

  const checkUserChipmentInfo = async () => {
    try {
      await userChipmentScheme.validate(form, { abortEarly: false });

      return true;
    } catch (error) {
      extractErrors((error as any).inner as any);
      return false;
    }
  };

  const checkUserInfo = async () => {
    try {
      await userInfoScheme.validate(form, { abortEarly: false });

      return true;
    } catch (error) {
      extractErrors((error as any).inner as any);
      return false;
    }
  };

  const validateForm = async () => {
    if (cart && cart.needsDelivery) {
      return checkUserChipmentInfo();
    } else {
      return checkUserInfo();
    }
  };

  const showError = (field: keyof Form) => errors[field] !== undefined;

  const setOrderCustomer = () => {
    setPreviousCustomer(form as Customer);
    updateCustomer(form as Customer);
  };

  const changeDelivery = (value: string) => {
    updateShoppingCart({ needsDelivery: value === "delivery" } as any);
  };

  const nextStep = async () => {
    const isValid = await validateForm();

    if (isValid) {
      setOrderCustomer();
      onStepComplete();
    }
  };

  console.log("cart", delivery);

  return (
    <Row className="mt-5 justify-content-between pb-4 mb-5 contact-info-step">
      <Col className="mb-3" md="12">
        <label style={{ fontWeight: 600 }} className="mb-3">
          Método de adquisición de productos
        </label>
        <div className="d-flex flex-row">
          <Radio
            className="me-5"
            label="En Guanathletics"
            checked={delivery === 'pickup'}
            onChange={(e) => changeDelivery(e)}
            value="pickup"
          />
          <Radio
            label="Envío a domicilio"
            checked={delivery === 'delivery'}
            onChange={(e) => changeDelivery(e)}
            value="delivery"
          />
        </div>
      </Col>
      <Col className="mb-3" md="5">
        <InputText
          value={form.name}
          placeholder="Nombre"
          onChange={(value) => updateFormField("name", value)}
          showError={showError("name")}
          error={errors.name && errors.name.error}
        />
      </Col>
      <Col className="mb-3" md="5">
        <InputText
          value={form.lastname}
          onChange={(value) => updateFormField("lastname", value)}
          placeholder="Apellidos"
          showError={showError("lastname")}
          error={errors.lastname && errors.lastname.error}
        />
      </Col>
      <Col className="mb-3" md="5">
        <InputText
          value={form.email}
          onChange={(value) => updateFormField("email", value)}
          placeholder="Correo electrónico"
          showError={showError("email")}
          error={errors.email && errors.email.error}
          type="email"
        />
      </Col>
      <Col className="mb-3" md="5">
        <InputText
          value={form.confirmEmail}
          onChange={(value) => updateFormField("confirmEmail", value)}
          placeholder="Confirma el correo electrónico"
          showError={showError("confirmEmail")}
          error={errors.confirmEmail && errors.confirmEmail.error}
          type="email"
        />
      </Col>
      <Col className="mb-3" md="5">
        <InputText
          value={form.phone}
          onChange={(value) => updateFormField("phone", value)}
          placeholder="Teléfono"
          showError={showError("phone")}
          error={errors.phone && errors.phone.error}
          type="number"
        />
      </Col>
      {cart && cart.needsDelivery && (
        <Col sm="12" className="justify-content-between">
          <Col sm="12">
            <h3>Dirección de envío</h3>
          </Col>
          <Row className="justify-content-between">
            <Col className="mb-3" md="5" style={{ paddingTop: 30 }}>
              <DropdownButton
                align={{ sm: "end" }}
                className={`w-100 ${
                  showError("province") &&
                  "border border-danger border-2 rounded"
                }`}
                variant="light"
                size="lg"
                id="dropdown-state-select"
                title={`Provincia${form.province ? `: ${form.province}` : ""}`}
              >
                {STATES.map((state) => (
                  <Dropdown.Item
                    key={state.value}
                    as="button"
                    onClick={() => updateFormField("province", state.label)}
                  >
                    {state.label}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
            <Col className="mb-3" md="5">
              <InputText
                value={form.city}
                onChange={(value) => updateFormField("city", value)}
                placeholder="Cantón"
                showError={showError("city")}
                error={errors.city && errors.city.error}
              />
            </Col>
            <Col className="mb-3" md="5">
              <InputText
                value={form.district}
                onChange={(value) => updateFormField("district", value)}
                placeholder="Distrito"
                showError={showError("district")}
                error={errors.district && errors.district.error}
              />
            </Col>
            <Col className="mb-3" md="5">
              <InputText
                value={form.zipCode}
                onChange={(value) => updateFormField("zipCode", value)}
                placeholder="Código postal"
                showError={showError("zipCode")}
                type="number"
                error={errors.zipCode && errors.zipCode.error}
              />
            </Col>
            <Col className="mb-3" md="5">
              <TextArea
                value={form.address}
                onChange={(value) => updateFormField("address", value)}
                placeholder="Dirección"
                showError={showError("address")}
                error={errors.address && errors.address.error}
              />
            </Col>
            <Col className="mb-3" md="5">
              <InputText
                value={form.documentId}
                onChange={(value) => updateFormField("documentId", value)}
                placeholder="Número de identificación"
                showError={showError("documentId")}
                error={errors.documentId && errors.documentId.error}
              />
            </Col>
          </Row>
        </Col>
      )}
      <Col sm="12" className="d-flex justify-content-center mt-4">
        <Button fitContent onClick={() => nextStep()}>
          Continuar
        </Button>
      </Col>
    </Row>
  );
};

export { ContactInfo };
