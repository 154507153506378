import { Notification } from "../../components/Notification";

class NotificationRef {
  static ref: Notification | null = null;

  static setNotificationRef(ref: any) {
    NotificationRef.ref = ref;
  }

  static getNotificationRef() {
    return NotificationRef.ref;
  }
}

export { NotificationRef };
