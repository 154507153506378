import { useState } from "react";

import { storage } from "../firebase.config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

interface Event<T = EventTarget> {
  target: T;
}

const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

export function Uploader(path: string) {
  const [urls, setUrls] = useState<string[] | []>([]);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");

  const handleFileChange = (
    e: Event<HTMLInputElement>,
    cb?: (urls: string[]) => void
  ) => {
    if (e.target.files) {
      setUrls([]);
      Array.from(e.target.files).forEach((file) => {
        if (file) {
          const fileType = file["type"];

          if (validImageTypes.includes(fileType)) {
            setError("");
            handleUpload(file, cb);
          } else {
            setError("Extensión de archivo invalida!");
          }
        }
      });
    }
  };

  const handleUpload = (img: File, cb?: (urls: string[]) => void) => {
    const storageRef = ref(storage, `${path}/${img.name}`);

    const uploadTask = uploadBytesResumable(storageRef, img);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const taskProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(taskProgress);
      },
      (error) => setError(error.message),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          setUrls((prevUrls) => {
            const updatedUrls = [...prevUrls, downloadUrl];

            if (cb) {
              cb(updatedUrls);
            }

            return updatedUrls;
          });
          setProgress(0);
        });
      }
    );
  };

  return {
    progress,
    urls,
    error,
    handleFileChange,
  };
}
