import React from "react";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import { openFlayer, mileFlayer } from "../../assets";

import "./styles.scss";

const Training = () => {
  return (
    <div className="view-container-training">
      <Container fluid="sm">
        <Row className="d-flex flex-row flex-xs-col justify-content-md-center">
          <Col xs={12} md={4} className="mb-4">
            <Card>
              <Card.Img alt="open flayer" variant="top" src={openFlayer} />
              <Card.Body className="d-flex justify-content-center">
                <Button
                  href="https://www.scoringdeck.com/leaderboard/647e0593e384f100024a31fc/general"
                  target="_blank"
                  variant="primary"
                >
                  Leaderboard
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} className="mb-4">
            <Card>
              <Card.Img alt="mile flayer" variant="top" src={mileFlayer} />
              <Card.Body className="d-flex justify-content-center">
                <Button
                  href="https://www.tiquetebox.app/e/milla_guanacasteca"
                  target="_blank"
                  variant="primary"
                >
                  Registrate aquí
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Training;
