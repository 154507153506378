import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";

import { Login } from "../views";

const AuthRouter = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <Navbar bg="light">
        <Container>
          <Navbar.Brand>Guanathletics portal access</Navbar.Brand>
        </Container>
      </Navbar>
      <Switch>
        <Route path={`${path}/login`}>
          <Login />
        </Route>
      </Switch>
    </div>
  );
};

export { AuthRouter };
