import { useMemo } from 'react';
import { Route, Switch } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { Classes, Prices, Training, Coaches, Store, Order } from "../views";
import { Header, Footer, AppBanner, NavBar } from "../components";

const AppRouter = () => {
  const { pathname } = useLocation();
  const isStore = pathname.includes("store");
  const isOrder = pathname.includes("order");
  const showBanner = !isOrder || !isStore;

  const routeName = useMemo(() => {
    if (pathname.includes('store')) return 'Tienda';

    if (pathname.includes('order')) return 'Orden';

    if (pathname.includes('staff')) return 'Staff';

    if (pathname.includes('retos')) return 'retos';

    return 'Clases'
  }, [pathname])

  return (
    <div>
      <NavBar hideCart={isOrder} route={routeName} />
      <Header />
      <Switch>
        <Route path="/store">
          <Store />
        </Route>
        <Route path="/prices">
          <Prices />
        </Route>
        <Route path="/staff">
          <Coaches />
        </Route>
        <Route path="/order">
          <Order />
        </Route>
        <Route exact path="/retos">
          <Training />
        </Route>
        <Route exact path="/">
          <Classes />
        </Route>
      </Switch>
      {!showBanner && <AppBanner />}
      <Footer />
    </div>
  );
};

export { AppRouter };
