import { Image } from "../Image";
import { Button } from '../Button';

import "./styles.scss";

type Props = {
  name: string;
  description: string;
  price: number;
  isInStock: boolean;
  img: string;
  onClick: () => void;
};

const ShopItem = ({ name, description, price, isInStock, img, onClick }: Props) => {
  return (
    <div className="justify-content-center shop-item">
      <Image source={img} />
      <div className="info">
        <span className="semibold">{name}</span>
        {isInStock ? <span className="semibold available">Disponible</span> : <span className="semibold unavailable">Agotado</span>}
        <span className="text description">{description}</span>
        <span className="price">{`₡${price}`}</span>
        <Button className="mt-2" onClick={onClick} style={{ width: '70%' }} disabled={!isInStock}>Añadir al carrito</Button>
      </div>
    </div>
  );
};

export { ShopItem };
