import { FunctionComponent } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useHistory } from "react-router-dom";

import {
  drawerClasses,
  drawerPrices,
  drawerStaff,
  drawerStore,
  drawerTrainig,
} from "../../assets";
import "./styles.scss";

type Props = {
  show: boolean;
  onClose?: () => void;
};

const routes = [
  {
    label: "Tienda",
    value: "/store",
    icon: drawerStore,
  },
  {
    label: "Clases",
    value: "/",
    icon: drawerClasses,
  },
  {
    label: "Staff",
    value: "/staff",
    icon: drawerStaff,
  },
  {
    label: "Tarifas",
    value: "/prices",
    icon: drawerPrices,
  },
  {
    label: "Retos",
    value: "/retos",
    icon: drawerTrainig,
  },
];

const NavDrawer: FunctionComponent<Props> = ({ show, onClose }) => {
  const history = useHistory();

  const navigate = (route: string) => {
    onClose && onClose();
    history.push({ pathname: route });
  };

  return (
    <Offcanvas
      className="nav-drawer-container"
      show={show}
      onHide={onClose}
      placement="start"
    >
      <Offcanvas.Body style={{ padding: 0 }}>
        <div className="w-100 mt-5 d-flex flex-column">
          {routes.map((route, index) => (
            <button
              className="py-3 px-5 w-100 d-flex flex-row align-items-center mb-3 drawer-item"
              key={`nav-drawer-route-${index}`}
              onClick={() => navigate(route.value)}
            >
              <div className="route-icon-holder d-flex justify-content-center align-items-center me-4">
                <img className="route-icon" src={route.icon} alt="" />
              </div>
              <label className="route">{route.label}</label>
            </button>
          ))}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export { NavDrawer };
