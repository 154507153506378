import { FunctionComponent, useEffect } from "react";

import { EventEmitter } from "./";
import { useStoreActions } from "../../store/typehook";

const EventProvider: FunctionComponent<{}> = ({ children }) => {
  const logout = useStoreActions((actions) => actions.auth.logout);
  const getOrders = useStoreActions((actions) => actions.orders.getOrders);

  useEffect(() => {
    EventEmitter.add("new-order", () => {
      getOrders();
    });
  }, [getOrders]);

  useEffect(() => {
    EventEmitter.add("logout", () => {
      logout();
    });
  }, [logout]);

  return <>{children}</>;
};

export { EventProvider };
