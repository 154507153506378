import { User } from "../store/auth/model";
import { request, privateRequest } from "./";

interface HttpResponse {
  data?: any;
  errorMessage?: string;
  error?: string;
}

export const login = async <T>(user: User): Promise<HttpResponse> => {
  return request<T>("POST", "auth/login", user) as any;
};

export const updateUser = async <T>(user: User): Promise<HttpResponse> => {
  return privateRequest<T>('PUT', "user", user) as any;
};
