import { useEffect, useMemo, useState } from "react";
import Carousel from "react-bootstrap/Carousel";

import { Button } from "../Button";
import { RadioVariant } from "../RadioVariant";
import { useStoreState, useStoreActions } from "../../store/typehook";
import { modalBackground } from "../../assets";
import isArray from "lodash/isArray";

import "./styles.scss";
import { UserProduct } from "../../store/products/model";
import { Col, Row } from "react-bootstrap";

type Props = {
  onClose?: () => void;
};

const ProductDetail = ({ onClose }: Props) => {
  const [index, setIndex] = useState(0);
  const [selectedVariant, setSelectedVariant] = useState<string>("");
  const [selectedSize, setSelectedSize] = useState<string>("");
  const currentProduct = useStoreState(
    (state) => state.products.user.currentProduct
  );
  const addProductToCart = useStoreActions(
    (actions) => actions.orders.addProductToCart
  );
  const toggleShoppingCart = useStoreActions(
    (actions) => actions.global.toggleShoppingCart
  );

  useEffect(() => {
    if (
      currentProduct &&
      currentProduct.variants &&
      currentProduct.productType === "variant"
    ) {
      const initialVariantKey = Object.keys(currentProduct.variants)[0];
      setSelectedVariant(initialVariantKey);
    }
  }, [currentProduct]);

  const imgData = useMemo(() => {
    if (currentProduct?.productType === "simple") {
      return currentProduct.imgs || [];
    } else if (selectedVariant && currentProduct?.variants) {
      return currentProduct.variants[selectedVariant].imgs;
    }

    return [];
  }, [currentProduct, selectedVariant]);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const changeVariant = (value: string) => {
    setSelectedVariant(value);
    setIndex(0);
  };

  const addProduct = (product: UserProduct) => {
    onClose && onClose();
    addProductToCart(product);
    toggleShoppingCart();
  };

  if (!currentProduct) return null;

  return (
    <Row className="product-detail-component-container">
      <div
        className="img-background"
        style={{ backgroundImage: `url(${modalBackground})` }}
      />
      <Col sm="12" md="5">
        <Carousel
          style={{
            // width: 414,
            // height: 587,
            // marginRight: 30,
            margin: 8,
            zIndex: 2,
            borderRadius: 28,
          }}
          activeIndex={index}
          onSelect={handleSelect}
          interval={null}
          indicators={imgData.length > 1}
          controls={imgData.length > 1}
          className="shadow"
        >
          {imgData.length > 0 ? (
            imgData.map((img, index) => (
              <Carousel.Item
                key={`carousel-item-${selectedVariant || ""}-${index}`}
                style={{ height: 587, borderRadius: 28 }}
              >
                <div
                  className="w-100 h-100 custom-carousel-item"
                  style={{
                    backgroundImage: `url(${img})`,
                  }}
                />
              </Carousel.Item>
            ))
          ) : (
            <Carousel.Item style={{ height: 587, borderRadius: 28 }}>
              <div
                className="w-100 h-100 custom-carousel-item"
                style={{
                  backgroundImage: `url(${imgData[0]})`,
                }}
              />
            </Carousel.Item>
          )}
        </Carousel>
      </Col>
      <Col sm="12" md="7" className="product-info-section py-5 px-4 px-md-1">
        <div className="product-info d-flex flex-column ">
          <label className="title">{currentProduct.name}</label>
          <label
            className={`availability ${
              currentProduct.isInStock ? "available" : "unavailable"
            }`}
          >
            {currentProduct.isInStock ? "Disponible" : "Agotado"}
          </label>
          {currentProduct.description && (
            <label className="description">{currentProduct.description}</label>
          )}
          {currentProduct.productType === "variant" && (
            <div className="mt-4">
              {currentProduct.variants && (
                <>
                  <label className="variant-color-label mb-3">Color</label>
                  <div className="d-flex flex-row justify-content-start flex-wrap mb-5">
                    {Object.keys(currentProduct.variants).map(
                      (variant, index) => (
                        <RadioVariant
                          key={`variant-color-${index}`}
                          color={currentProduct.variants![variant].value}
                          value={variant}
                          onClick={(value) => changeVariant(value)}
                          selected={selectedVariant === variant}
                        />
                      )
                    )}
                  </div>
                </>
              )}
              {currentProduct.sizes && currentProduct.sizes.length > 0 && (
                <>
                  <label className="variant-color-label mb-3">Tamaños</label>
                  <div className="d-flex flex-row justify-content-start flex-wrap">
                    {isArray(currentProduct.sizes) &&
                      currentProduct.sizes.map((size, index) => (
                        <RadioVariant
                          key={`variant-size-${index}`}
                          value={size}
                          onClick={(value) => setSelectedSize(value)}
                          selected={size === selectedSize}
                          type="size"
                        >
                          {size}
                        </RadioVariant>
                      ))}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <Button
          fitContent
          disabled={
            currentProduct &&
            ((isArray(currentProduct.sizes) &&
              currentProduct.sizes &&
              currentProduct.sizes.length > 0 &&
              (!setSelectedSize || selectedSize === "")) ||
              (currentProduct.variants && !selectedVariant))
          }
          onClick={() =>
            addProduct({
              name: currentProduct.name,
              description: currentProduct.description,
              quantity: 1,
              total: currentProduct.price,
              subtotal: currentProduct.price,
              img: imgData[0],
              selectedColor: selectedVariant ? selectedVariant : undefined,
              selectedSize: selectedSize ? selectedSize : undefined,
              id: currentProduct.id,
            })
          }
        >
          Listo
        </Button>
      </Col>
    </Row>
  );
};

export { ProductDetail };
