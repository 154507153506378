import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Card,
  Button,
  Badge,
  Modal,
} from "react-bootstrap";

import { useStoreActions, useStoreState } from "../../../store/typehook";
import { editIcon } from "../../../assets";
import { Product } from "../../../store/products/model";
import { getDateFormat } from "../../../utils/time";

const Products = () => {
  const getProducts = useStoreActions(
    (actions) => actions.products.getProducts
  );
  const setCurrentProduct = useStoreActions(
    (actions) => actions.products.setCurrentProduct
  );
  const deleteProduct = useStoreActions(
    (actions) => actions.products.deleteProduct
  );
  const products = useStoreState((state) => state.products.portal.data);
  const isLoading = useStoreState((state) => state.products.loading);
  const [showModal, setShowModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");
  const history = useHistory();

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const addNewProduct = () => {
    setCurrentProduct(undefined);
    history.push({ pathname: "/portal/products/add" });
  };

  const editProduct = (product: Product) => {
    history.push({ pathname: "/portal/products/edit" });
    setCurrentProduct(product);
  };

  const handleConfirmDelete = () => {
    if (deleteItem) {
      deleteProduct(deleteItem);
      setShowModal(false);
    }
  };

  return (
    <Container>
      {!isLoading && (
        <Row className="mb-3">
          <Col sm="8">
            <h4>Productos</h4>
          </Col>
          <Col sm="4">
            <div className="d-flex justify-content-end">
              <Button variant="primary" onClick={addNewProduct}>
                Agregar producto
              </Button>
            </div>
          </Col>
        </Row>
      )}
      <Row className="justify-content-center">
        {isLoading ? (
          <div className="mt-5 d-flex justify-content-center">
            <Spinner animation="grow" variant="primary" />
          </div>
        ) : (
          <>
            {products.length > 0 ? (
              <Col sm="12" className="justify-content-center">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>Precio</th>
                      <th>Disponibles</th>
                      <th>En stock</th>
                      <th>Estado</th>
                      <th>Creado</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product, i) => (
                      <tr key={product.id}>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-center">{product.name}</td>
                        <td className="text-center">₡{product.price}</td>
                        <td className="text-center">
                          {product.stockMaintenance ? product.available : "∞"}
                        </td>
                        <td className="text-center">
                          <label
                            className={`${
                              product.isInStock ? "text-success" : "text-danger"
                            }`}
                          >
                            {product.isInStock ? "Si" : "No"}
                          </label>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center w-100">
                            <Badge
                              pill
                              bg={`${
                                product.status === "draft"
                                  ? "secondary"
                                  : "success"
                              }`}
                            >
                              {`${
                                product.status === "draft"
                                  ? "Borrador"
                                  : "Publico"
                              }`}
                            </Badge>
                          </div>
                        </td>
                        <td className="text-center">
                          {product.createdAt &&
                            getDateFormat(product.createdAt)}
                        </td>
                        <td>
                          <div className="d-flex justify-content-evenly">
                            <Badge
                              bg="light"
                              style={{ cursor: "pointer" }}
                              onClick={() => editProduct(product)}
                            >
                              <img
                                src={editIcon}
                                style={{ height: 18 }}
                                alt=""
                              />
                            </Badge>
                            <Badge
                              bg="danger"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowModal(true);
                                setDeleteItem(product.id!);
                              }}
                            >
                              Eliminar
                            </Badge>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            ) : (
              <Card>
                <Card.Body>
                  <p>No hay productos agregados todavia</p>
                  <Button variant="primary" onClick={addNewProduct}>
                    Agregar producto
                  </Button>
                </Card.Body>
              </Card>
            )}
          </>
        )}
      </Row>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setDeleteItem("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Borrar producto?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Estas seguro que quieres eliminar este producto?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
              setDeleteItem("");
            }}
          >
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export { Products };
