import React from "react";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import { Link } from "react-router-dom";
import { EventEmitter } from "../../services/eventEmitter";

interface State {
  isOpen: boolean;
  data: any[];
}

export class Notification extends React.PureComponent<{}, State> {
  private closeInterval?: ReturnType<typeof setInterval>;

  constructor(props: {}) {
    super(props);

    this.state = {
      isOpen: false,
      data: [],
    };
  }

  private clearCloseInterval() {
    if (this.closeInterval) {
      clearInterval(this.closeInterval);
    }
  }

  private closeAutomatic(timing?: number) {
    const timeInterval = timing ? timing : 5000;
    this.closeInterval = setTimeout(() => {
      this.handleClose();
    }, timeInterval);
  }

  private removeItem(index: number | undefined = undefined, cb: () => void) {
    let updatedData = [];
    const auxData = this.state.data;

    if (index && index >= 0) {
      auxData.splice(index, 1);
      updatedData = auxData;
    } else {
      auxData.pop();
      updatedData = auxData;
    }

    this.setState(
      {
        data: [...updatedData],
      },
      () => cb()
    );
  }

  private handleClose(index?: number) {
    this.removeItem(index, () => {
      if (this.state.data.length === 0) {
        this.clearCloseInterval();
        this.setState({ isOpen: false });
      }
    });
  }

  private closeByTab = (index: number) => {
    this.handleClose(index);
  };

  public async pushNotification(data: any) {
    this.setState(
      (prevState) => ({
        ...prevState,
        isOpen: true,
        data: [...prevState.data, data],
      }),
      () => this.closeAutomatic()
    );
  }

  handleNotification = (data: any, index: number) => {
    if (data && data.orderCode) {
      EventEmitter.emit("new-order");
    }

    this.closeByTab(index);
  };

  render() {
    const { isOpen } = this.state;

    if (!isOpen) {
      return null;
    }

    return (
      <ToastContainer className="ms-3 mb-4 fixed-bottom">
        {this.state.data.length > 0 &&
          this.state.data.map((data, i) => (
            <Toast key={`notification-${i}`} onClose={() => this.closeByTab(i)}>
              <Toast.Header>
                <strong className="me-auto">Nueva orden!</strong>
                <small className="text-muted">justo ahora</small>
              </Toast.Header>
              <Toast.Body
                className="p-0"
                onClick={() => this.handleNotification(data, i)}
              >
                <Link
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "block",
                    padding: "0.75rem",
                    color: "unset",
                    cursor: "pointer",
                  }}
                  to={{
                    pathname: data.orderCode
                      ? `/portal/order/${data.orderCode}`
                      : "/portal/orders",
                  }}
                >
                  <label className="my-2" style={{ cursor: "pointer" }}>
                    Click aca para ir a la orden{" "}
                    {data.orderCode && (
                      <label
                        style={{ cursor: "pointer" }}
                        className="text-uppercase fw-bold fst-italic text-primary"
                      >{`#${data.orderCode}`}</label>
                    )}
                  </label>
                </Link>
              </Toast.Body>
            </Toast>
          ))}
      </ToastContainer>
    );
  }
}
