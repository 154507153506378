export const getOrderStatus = (status: "pending" | "completed" | "returned") => {
  const statusDictionary = {
    pending: {
      class: "text-warning",
      value: "Pendiente",
    },
    completed: {
      class: "text-success",
      value: "Completado",
    },
    returned: {
      class: "text-danger",
      value: "Devuelto",
    },
  };

  return statusDictionary[status];
};
