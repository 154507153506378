import { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";

import { copyIcon } from "../../assets";
import { Card, Radio, Button as CButton } from "../../components";
import { useStoreActions } from "../../store/typehook";

type Props = {
  onStepComplete: () => void;
};

const PaymentMethod = ({ onStepComplete }: Props) => {
  const [payment, setPayment] = useState<"sinpe" | "deposit">("sinpe");
  const setPaymentMethod = useStoreActions(
    (actions) => actions.orders.setOrderPaymentMethod
  );

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  const nextStep = () => {
    setPaymentMethod(payment);
    onStepComplete();
  };

  return (
    <Row className="mt-5 justify-content-around pb-4 mb-5 payment-method-step">
      <Col className="mb-3" md="8">
        <Card>
          <div className="payment-toggle top">
            <Radio
              checked={payment === "sinpe"}
              label={"SINPE Móvil"}
              value={"sinpe"}
              onChange={() => setPayment("sinpe")}
              className="m-0"
            />
            {payment === "sinpe" && (
              <div className="d-flex justify-content-center align-items-center p-3">
                <Col sm="8">
                  <Card flat className="px-2">
                    <Row>
                      <Col
                        sm="12"
                        className="d-flex flex-column align-items-center py-4"
                      >
                        <label className="mb-2">José Rodríguez Pérez</label>
                        <div className="d-flex flex-row align-items-center">
                          <label>Teléfono: 61061879</label>
                          <Button
                            variant="light"
                            className="copy-icon p-1"
                            onClick={() => copyToClipboard("61061879")}
                          >
                            <img src={copyIcon} alt="" />
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </div>
            )}
          </div>
          <div>
            <div className="payment-toggle bottom">
              <Radio
                checked={payment === "deposit"}
                label={"Transferencia electrónica"}
                value={"deposit"}
                onChange={() => setPayment("deposit")}
                className="m-0"
              />
            </div>
            {payment === "deposit" && (
              <div className="d-flex justify-content-center align-items-center p-3">
                <Col sm="8" className="mb-3">
                  <Card flat className="px-2">
                    <Row>
                      <Col
                        sm="12"
                        className="d-flex flex-column align-items-center py-4"
                      >
                        <Col
                          sm="12"
                          className="d-flex flex-column mb-3 align-items-center"
                        >
                          <label className="mb-2">
                            A nombre de José Rodríguez Pérez
                          </label>
                          <div className="d-flex flex-row align-items-center">
                            <label>Cédula física: 503700517</label>
                            <Button
                              variant="light"
                              className="copy-icon p-1"
                              onClick={() => copyToClipboard("503700517")}
                            >
                              <img src={copyIcon} alt="" />
                            </Button>
                          </div>
                        </Col>
                        <Col
                          sm="12"
                          className="d-flex flex-column align-items-center"
                        >
                          <label>Cuenta Banco BCR</label>
                          <label className="mb-4">Colones</label>
                          <div className="d-flex flex-row align-items-center">
                            <label>CR59015202001050112211</label>
                            <Button
                              variant="light"
                              className="copy-icon p-1"
                              onClick={() =>
                                copyToClipboard("CR59015202001050112211")
                              }
                            >
                              <img src={copyIcon} alt="" />
                            </Button>
                          </div>
                        </Col>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </div>
            )}
          </div>
        </Card>
      </Col>
      <Col
        sm="12"
        style={{ marginTop: 100 }}
        className="d-flex justify-content-center"
      >
        <CButton fitContent onClick={() => nextStep()}>
          Continuar
        </CButton>
      </Col>
    </Row>
  );
};

export { PaymentMethod };
