import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Card,
  Button,
  Badge,
  Modal,
} from "react-bootstrap";

import { useStoreActions, useStoreState } from "../../../store/typehook";
import { editIcon } from "../../../assets";
import { getDateFormat } from "../../../utils/time";
import { Order } from "../../../store/orders/model";

const Orders = () => {
  const getOrders = useStoreActions((actions) => actions.orders.getOrders);
  const orders = useStoreState((state) => state.orders.data);
  const isLoading = useStoreState((state) => state.orders.loading);
  const [showModal, setShowModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");
  const history = useHistory();

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  const editOrder = (orderCode: string) => {
    history.push({ pathname: `/portal/order/${orderCode}` });
    // setCurrentProduct(product);
  };

  const getOrderStatus = (status: "pending" | "completed" | "returned") => {
    const statusDictionary = {
      pending: {
        class: "text-warning",
        value: "Pendiente",
      },
      completed: {
        class: "text-success",
        value: "Completado",
      },
      returned: {
        class: "text-danger",
        value: "Devuelto",
      },
    };

    return statusDictionary[status];
  };

  return (
    <Container>
      {!isLoading && (
        <Row className="mb-3">
          <Col sm="8">
            <h4>Ordenes</h4>
          </Col>
        </Row>
      )}
      <Row className="justify-content-center">
        {isLoading ? (
          <div className="mt-5 d-flex justify-content-center">
            <Spinner animation="grow" variant="primary" />
          </div>
        ) : (
          <>
            {orders.length > 0 ? (
              <Col sm="12" className="justify-content-center">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Codigo</th>
                      <th>Necesita entrega</th>
                      <th>Total</th>
                      <th>Estado</th>
                      <th>Cliente</th>
                      <th>Creado</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order, i) => (
                      <tr key={order.id}>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-center text-uppercase">
                          {order.orderCode}
                        </td>
                        <td className="text-center">
                          <label>{order.needsDelivery ? "Si" : "No"}</label>
                        </td>
                        <td className="text-center">₡{order.total}</td>
                        <td className="text-center">
                          <label
                            className={`${getOrderStatus(order.status).class}`}
                          >
                            {getOrderStatus(order.status).value}
                          </label>
                        </td>
                        <td>
                          <div className="d-flex flex-column w-100 h-100 justify-content-start align-items-center">
                            <label style={{ fontSize: 14 }}>
                              {order.customer.name}
                            </label>
                            <small
                              style={{ fontSize: 11 }}
                              className="fw-lighter"
                            >
                              {order.customer.email}
                            </small>
                          </div>
                        </td>
                        <td className="text-center">
                          {order.createdAt && getDateFormat(order.createdAt)}
                        </td>
                        <td>
                          <div className="d-flex justify-content-evenly">
                            <Badge
                              bg="light"
                              style={{ cursor: "pointer" }}
                              onClick={() => editOrder(order.orderCode)}
                            >
                              <img
                                src={editIcon}
                                style={{ height: 18 }}
                                alt=""
                              />
                            </Badge>
                            {/* <Badge
                              bg="danger"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowModal(true);
                                setDeleteItem(product.id!);
                              }}
                            >
                              Eliminar
                            </Badge> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            ) : (
              <Card>
                <Card.Body>
                  <p>No hay ordenes todavia</p>
                </Card.Body>
              </Card>
            )}
          </>
        )}
      </Row>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setDeleteItem("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Borrar producto?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Estas seguro que quieres eliminar este producto?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
              setDeleteItem("");
            }}
          >
            Cancelar
          </Button>
          <Button variant="danger" onClick={() => {}}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export { Orders };
