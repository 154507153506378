import axios, { Method } from "axios";
import get from "lodash/get";

import { getEnvironment } from '../constants/env';
import { EventEmitter } from '../services/eventEmitter'

interface HttpResponse {
  data?: any;
  errorMessage?: string;
  error?: string;
}

export const request = async <T>(
  method: Method,
  url: string,
  data?: object
): Promise<HttpResponse> => {
  try {
    const { apiUrl } = getEnvironment();

    const response = await axios.request<T>({
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
      method,
      url: `${apiUrl}${url}`,
      data,
    });

    return {
      data: response.data as T,
    };
  } catch (error) {
    const errorMessage = get(error, "response.data.message", undefined);

    return {
      errorMessage,
      error: error as string,
    };
  }
};

export const privateRequest = async <T>(method: Method, url: string, data?: object): Promise<HttpResponse> => {
  try {
    const { apiUrl } = getEnvironment();
    const token = localStorage.getItem('token');

    const response = await axios.request<T>({
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        'Authorization': `Bearer ${token}`,
      },
      method,
      url: `${apiUrl}${url}`,
      data,
    });

    return {
      data: response.data as T,
    };
  } catch (error) {
    const errorMessage = get(error, "response.data.message", undefined);
    const statusCode = get(error, 'response.data.statusCode');

    if (statusCode && statusCode === 401) {
      EventEmitter.emit('logout');
    }

    return {
      errorMessage,
      error: error as string,
    };
  }
}
