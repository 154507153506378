import React, { useState, useEffect } from "react";

import { logo } from "../../assets";

import "./styles.css";

const Header = () => {
  const [loaded, setLoaded] = useState(false);
  const height = (43 * window.screen.height) / 100;

  useEffect(() => {
    setLoaded(true);
  }, []);

  const top = (30 * height) / 100;
  const loadedHeigth = loaded
    ? { transform: `translateY(calc(${top}px + 100px))` }
    : {};

  return (
    <div className="header" style={{ height }}>
      <div className="background-holder">
        <div className="header-background"></div>
      </div>
      <div className="header-logo header-logo" style={loadedHeigth}>
        <img className="header-logo-background" alt="logo" src={logo} />
      </div>
    </div>
  );
};

export default Header;
