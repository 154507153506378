import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import "./styles.scss";

const coachesMock = [
  {
    imgs: [
      "https://firebasestorage.googleapis.com/v0/b/guanathletics.appspot.com/o/jose-coach.jpeg?alt=media&token=28fa5f90-243c-4ce0-9252-65086aae3ed7",
      "https://firebasestorage.googleapis.com/v0/b/guanathletics.appspot.com/o/jose-cert.jpeg?alt=media&token=b7bfbb26-4ee8-46b6-a090-64f42eb3839b",
    ],
  },
  {
    imgs: [
      "https://firebasestorage.googleapis.com/v0/b/guanathletics.appspot.com/o/charlie-coach.jpeg?alt=media&token=26d6ef9c-8afc-44ab-a556-2208174094c1",
      "https://firebasestorage.googleapis.com/v0/b/guanathletics.appspot.com/o/charlie-cert.jpeg?alt=media&token=cad2b1b5-b425-45c3-b968-9d24aae6a8e4",
    ],
  },
];

const Coaches = () => {
  return (
    <div className="view-container">
      <Container fluid="sm">
        <Row className="justify-content-md-center">
          <Col md="12" sm="6">
            {coachesMock.map((coach, index) => (
              <Row
                key={`coach_${index}`}
                className="justify-content-md-center img-row-holder"
              >
                {coach.imgs.map((img, i) => (
                  <Col key={`img_${i}`} className="img-holder" md="6" sm="12">
                    <img className="img" alt="schedule" src={img} />
                  </Col>
                ))}
              </Row>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Coaches;
