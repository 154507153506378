import { Route, Switch, useRouteMatch, RouteProps } from "react-router-dom";

import { PortalNavBar } from "../components";
import { Products } from "../views/Portal/Products";
import { ManageProduct } from "../views/Portal/Products/ManageProduct";
import { Orders } from "../views/Portal/Orders";
import { OrderDetail } from "../views/Portal/Orders/OrderDetail";

const PortalRouter = (props: RouteProps) => {
  const { path } = useRouteMatch();

  return (
    <div>
      <PortalNavBar />
      <div className="pt-5">
        <Switch>
          <Route path={`${path}/order/:id`}>
            <OrderDetail />
          </Route>
          <Route path={`${path}/orders`}>
            <Orders />
          </Route>
          <Route path={`${path}/products/add`}>
            <ManageProduct />
          </Route>
          <Route path={`${path}/products/edit`}>
            <ManageProduct />
          </Route>
          <Route path={`${path}/products`}>
            <Products />
          </Route>
          <Route exact path={`${path}`}>
            <Products />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export { PortalRouter };
