import { Image } from "../Image";
import { ButtonAction } from "../ButtonAction";
import { minusIcon, plusIcon, trashIcon } from "../../assets";

import "./styles.scss";

type Props = {
  img: string;
  name: string;
  price: string | number;
  onDecrease: () => void;
  onIncrease: () => void;
  onDelete: () => void;
  quantity: number;
};

const ProductCard = ({
  img,
  name,
  price,
  onDecrease,
  onIncrease,
  onDelete,
  quantity
}: Props) => (
  <div className="d-flex flex-column align-items-center justify-content-start w-100 shopping-card shadow-sm">
    <Image type="small" source={img} />
    <div className="d-flex flex-column align-items-center mt-4 mb-4">
      <label className="name">{name}</label>
      <label className="price">₡{price}</label>
    </div>
    <div className="w-100 d-flex justify-content-center">
      <ButtonAction
        actions={[
          {
            onClick: () => onDecrease(),
            icon: minusIcon,
          },
          {
            onClick: () => onIncrease(),
            icon: plusIcon,
          },
        ]}
      >
        {quantity}
      </ButtonAction>
      <ButtonAction
        actions={{
          onClick: () => onDelete(),
          icon: trashIcon,
        }}
      ></ButtonAction>
    </div>
  </div>
);

export { ProductCard };
