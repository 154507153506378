import { action } from "easy-peasy";

import { GlobalModel } from "./model";

const global: GlobalModel = {
  showShoppingCart: false,
  showDrawer: false,
  toggleShoppingCart: action((state) => {
    const show = !state.showShoppingCart;
    state.showShoppingCart = show;
  }),
  toggleDrawer: action((state) => {
    const show = !state.showDrawer;
    state.showDrawer = show;
  }),
  setPreviousCustomer: action((state, payload) => {
    state.previousCustomer = payload;
  }),
};

export { global };
