import { useEffect, useState } from "react";
import { Container, Row, Col, Spinner, Card, Modal } from "react-bootstrap";

import { useStoreActions, useStoreState } from "../../store/typehook";
import { ShopItem, ProductDetail } from "../../components";
import { getEnvironment } from "../../constants/env";
import { Product } from "../../store/products/model";

import "./styles.scss";

const Store = () => {
  const { showStore } = getEnvironment();
  const [showModal, setShowModal] = useState(false);
  const getProducts = useStoreActions(
    (actions) => actions.products.getPublishedProducts
  );
  const setCurrentProduct = useStoreActions(
    (actions) => actions.products.setCurrentUserProduct
  );
  const removeCurrentProduct = useStoreActions(
    (actions) => actions.products.removeCurrentUserProduct
  );
  const isLoading = useStoreState((state) => state.products.loading);
  const products = useStoreState((state) => state.products.user.data);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const openModal = (product: Product) => {
    setCurrentProduct(product);
    setShowModal(true);
  };

  const onClose = () => {
    setShowModal(false);
    removeCurrentProduct();
  };

  if (!showStore) {
    return (
      <div className="view-container-training">
        <Container fluid="sm">
          <Row className="justify-content-md-center">
            <Col sm="6">
              <Card>
                <Card.Body>
                  <h2>Muy pronto!</h2>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div className="view-container-training">
      <Container fluid="sm">
        {isLoading ? (
          <Row className="justify-content-center">
            <Spinner animation="grow" variant="primary" />
          </Row>
        ) : (
          <Row className="justify-content-start">
            {products &&
              products.length > 0 &&
              products.map((product) => (
                <Col
                  key={product.id}
                  className="justify-content-center mb-5"
                  xs={12}
                  md={4}
                  xl={3}
                >
                  <ShopItem
                    name={product.name}
                    isInStock={product.isInStock}
                    price={product.price}
                    description={product.description || ""}
                    img={product.thumbnail}
                    onClick={() => openModal(product)}
                  />
                </Col>
              ))}
          </Row>
        )}
        {/* <Modal
          show={showModal}
          onClose={() => onClose()}
          style={{ width: "100%", maxWidth: 988 }}
        >
          <ProductDetail onClose={() => onClose()} />
        </Modal> */}
        <Modal keyboard onEscapeKeyDown={onClose} show={showModal} onHide={() => onClose()} className="product-detail-component-container-modal">
          <Modal.Header closeButton style={{ borderBottom: '0' }} />
          <ProductDetail onClose={() => onClose()} />
        </Modal>
      </Container>
    </div>
  );
};

export default Store;
