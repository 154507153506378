import React from "react";

import "./styles.scss";

type Props = {
  source: string;
  type?: "big" | "small";
  className?: string;
};

const Image = ({ source, type = "big", className = "" }: Props) => (
  <div
    className={`image-component ${type} ${className}`}
    style={{ backgroundImage: `url(${source})` }}
  />
);

export { Image };
