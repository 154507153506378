import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  ListGroup,
  Image,
  Badge,
  Button,
  Form,
  Modal,
  Alert,
} from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useStoreActions, useStoreState } from "../../../../store/typehook";
import { getOrderStatus } from "../../../../utils/orderStatus";
import { getDateFormat } from "../../../../utils/time";

const OrderDetail = () => {
  const getOrder = useStoreActions((actions) => actions.orders.getOrder);
  const resetOrder = useStoreActions(
    (actions) => actions.orders.resetCurrentOrder
  );
  const updateTrackingId = useStoreActions(
    (actions) => actions.orders.putOrderTrackingId
  );
  const returnOrder = useStoreActions((actions) => actions.orders.returnOrder);
  const completeOrder = useStoreActions(
    (actions) => actions.orders.putOrderComplete
  );
  const order = useStoreState((state) => state.orders.currentOrder);
  const isLoading = useStoreState((state) => state.orders.loading);
  const showSuccess = useStoreState((state) => state.orders.showSuccess);
  const error = useStoreState((state) => state.orders.error);
  const [trackingId, setTrackingId] = useState("");
  const [showTrackingModal, setShowTrackingModal] = useState(false);
  const [showReturnModal, setShowReturnModal] = useState(false);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      getOrder(id);
    }
  }, [getOrder, id]);

  useEffect(() => {
    setTimeout(() => {
      resetOrder();
    }, 3000);
  }, [showSuccess, resetOrder]);

  useEffect(() => {
    if (order && order.trackingId) {
      setTrackingId(order.trackingId);
    }
  }, [order]);

  if (isLoading) {
    return (
      <Container>
        <Row className="justify-content-center">
          <div className="mt-5 d-flex justify-content-center">
            <Spinner animation="grow" variant="primary" />
          </div>
        </Row>
      </Container>
    );
  }

  if (!order) {
    return (
      <Container>
        <Row className="justify-content-center">
          <Col sm="4">
            <Card>
              <Card.Body>No se encontro ninguna orden!</Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      {showSuccess && (
        <Row className="mb-2">
          <Col>
            <Alert variant="success" dismissible onClose={() => resetOrder()}>
              <Alert.Heading>Perfecto! ✅</Alert.Heading>
              <p>{showSuccess}</p>
            </Alert>
          </Col>
        </Row>
      )}
      {error && (
        <Row className="mb-2">
          <Col>
            <Alert variant="danger" dismissible onClose={() => resetOrder()}>
              <Alert.Heading>Ops! algo salió mal!</Alert.Heading>
              <p>{error}</p>
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm="9">
          {order.needsDelivery && (
            <Card className="mb-4">
              <Card.Body>
                <h4 className="mb-3">Direccion</h4>
                <Row>
                  <Col sm="4">
                    <div className="d-flex flex-column">
                      <label>{`${
                        order.status === "pending" ? "Entregar" : "Entregado"
                      } en`}</label>

                      <label className="fw-bold">{`${order.customer.province}, ${order.customer.city}`}</label>
                      <label>{`${order.customer.district}, ${order.customer.zipCode}`}</label>
                      <label>{`${order.customer.address}`}</label>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="d-flex flex-column">
                      <label>Para</label>
                      <label className="fw-bold">{`${order.customer.name}, ${order.customer.documentId}`}</label>
                      <label>{`${order.customer.phone}, ${order.customer.email}`}</label>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
          <Card className="mb-4">
            <Card.Header>
              <Row>
                <Col sm="9">
                  <Row>
                    <Col sm="4" className="d-flex flex-column">
                      <label>Creado</label>
                      <label>{getDateFormat(order.createdAt, true)}</label>
                    </Col>
                    <Col sm="3" className="d-flex flex-column">
                      <label>Total</label>
                      <label>₡{order.total}</label>
                    </Col>
                  </Row>
                </Col>
                <Col sm="3">
                  <div className="text-end">
                    <label>Orden</label>
                    <label className="ms-1 text-uppercase">{`#${order.orderCode}`}</label>
                  </div>
                  <div
                    className={`text-end ${getOrderStatus(order.status).class}`}
                  >
                    {getOrderStatus(order.status).value}
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <ListGroup variant="flush">
              <h4 className="mb-4 mt-3 ms-3">Productos</h4>
              {order.products.length > 0 &&
                order.products.map((item, i) => (
                  <ListGroup.Item key={`product-${i}`}>
                    <Row>
                      <Col sm="3">
                        <Image thumbnail src={item.img} />
                      </Col>
                      <Col sm="9" className="d-flex justify-content-between">
                        <div className="d-flex flex-column">
                          <label className="fw-bold">{item.name}</label>
                          <label>{item.description}</label>
                          {item.selectedSize && <label>Talla: {item.selectedSize}</label>}
                          <Badge bg="light" text="dark" className="w-50 mt-3">
                            Cant: {item.quantity}
                          </Badge>
                        </div>
                        <div>
                          <label style={{ fontSize: 14 }}>
                            ₡{item.total} c/u
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              {order.needsDelivery && (
                <ListGroup.Item key={`product-chip-amount`}>
                  <Row className="justify-content-end">
                    <Col sm="3" className="d-flex flex-column text-end">
                      <label>subtotal: ₡{order.subtotal}</label>
                      <label>envio: ₡{order.deliveryFee || 2500}</label>
                      <div
                        style={{ border: "1px solid" }}
                        className="w-100 h-0 border-dark"
                      />
                      <label className="fw-bold mt-1">
                        total: ₡{order.total}
                      </label>
                    </Col>
                  </Row>
                </ListGroup.Item>
              )}
            </ListGroup>
          </Card>
        </Col>
        <Col sm="3">
          {order.status === "pending" && (
            <Card className="mb-3">
              <Card.Header>Accion de la orden</Card.Header>
              <Card.Body>
                <label>Completar orden</label>
                <div className="mb-3">
                  <Button
                    disabled={
                      order.needsDelivery &&
                      (!order.trackingId || order.trackingId === "")
                    }
                    variant="success"
                    className="mt-2 w-100"
                    onClick={() => completeOrder(order.id)}
                  >
                    Completar
                  </Button>
                  {order.needsDelivery &&
                    (!order.trackingId || order.trackingId === "") && (
                      <Form.Text className="mt-2" muted>
                        Esta orden necesita el numero de rastreo para poder ser
                        completada.
                      </Form.Text>
                    )}
                </div>
                <label>Retornar orden</label>
                <Button
                  variant="danger"
                  className="mt-2 w-100"
                  onClick={() => setShowReturnModal(true)}
                >
                  Devolver
                </Button>
              </Card.Body>
            </Card>
          )}

          {order.needsDelivery && (
            <Card className="mb-3">
              <Card.Header>Número de rastreo</Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group controlId="trackingId">
                    <Form.Control
                      type="text"
                      value={trackingId}
                      disabled={order.status !== "pending"}
                      onChange={(e) => setTrackingId(e.target.value)}
                    />
                    <Form.Text className="mt-2" muted>
                      Este número se obtiene del proveedor de mensajería que se
                      utilizará para el envío de esta orden
                    </Form.Text>
                  </Form.Group>
                  {order.status === "pending" && !order.trackingId && (
                    <Button
                      className="mt-3 w-100"
                      disabled={!trackingId || trackingId === ""}
                      variant="primary"
                      onClick={() => setShowTrackingModal(true)}
                    >
                      Actualizar
                    </Button>
                  )}
                </Form>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      <Modal
        centered
        show={showTrackingModal}
        onHide={() => {
          setShowTrackingModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Actualizar el número de rastreo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Asignar <strong>{`#${trackingId}`}</strong> como número de rastreo
          para esta orden?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowTrackingModal(false)}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowTrackingModal(false);
              updateTrackingId({
                orderId: order.id,
                trackingId,
              });
            }}
          >
            Asignar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        centered
        show={showReturnModal}
        onHide={() => {
          setShowReturnModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Retornar orden</Modal.Title>
        </Modal.Header>
        <Modal.Body>Estas seguro que deseas retornar la orden?</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowReturnModal(false)}>
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              returnOrder(order.id);
              setShowReturnModal(false);
            }}
          >
            Retornar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export { OrderDetail };
