import { Navbar, Container, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import { logoutIcon } from "../../assets";
import { useStoreActions } from "../../store/typehook";

const PortalNavBar = () => {
  const logout = useStoreActions((actions) => actions.auth.logout);
  const { pathname } = useLocation();
  const isOrders = pathname.includes("orders") || pathname.includes("order");
  const isProducts =
    pathname.includes("products") || (pathname.includes("portal") && !isOrders); // <== this beacause is default screen;

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand>Guanathletics portal</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="/dashboard">Dashboard</Nav.Link> */}
            <Link
              className={`${isProducts ? "text-primary fw-bold" : ""} px-2`}
              style={{ color: "inherit", textDecoration: "unset" }}
              to="/portal/products"
            >
              Productos
            </Link>
            <Link
              className={`${isOrders ? "text-primary fw-bold" : ""} px-2`}
              style={{ color: "inherit", textDecoration: "unset" }}
              to="/portal/orders"
            >
              Ordenes
            </Link>
          </Nav>
        </Navbar.Collapse>
        <div
          className="p-1"
          style={{ cursor: "pointer" }}
          onClick={() => logout()}
        >
          <img style={{ width: 17 }} src={logoutIcon} alt="" />
        </div>
      </Container>
    </Navbar>
  );
};

export { PortalNavBar };
