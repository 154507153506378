import * as yup from "yup";

const errors = {
  required: "Este campo es requerido",
  format: 'Digite un formato adecuado',
};

export const userInfoScheme = yup.object().shape({
  name: yup.string().required(errors.required).min(1).trim(),
  lastname: yup.string().min(1).required(errors.required),
  email: yup
    .string()
    .email(errors.format)
    .trim()
    .required(errors.required),
  phone: yup
    .string()
    .trim()
    .matches(/\d+/, errors.format)
    .min(8, "El número debe de ser de 8 dígitos")
    .max(8, "El número debe de ser de 8 dígitos")
    .required(errors.required),
  confirmEmail: yup
    .string()
    .required(errors.required)
    .oneOf([yup.ref("email")], "Debe ser igual al correo electrónico"),
});

export const userChipmentScheme = yup.object().shape({
  name: yup.string().required(errors.required).min(1).trim(),
  lastname: yup.string().min(1).required(errors.required).trim(),
  address: yup.string().min(1).required(errors.required).trim(),
  zipCode: yup.number().min(1).required(errors.required),
  documentId: yup.string().min(5, 'Debe tener un mínimo de 5 dígitos').required(errors.required).trim(),
  province: yup.string().min(1).required(errors.required).trim(),
  city: yup.string().min(1).required(errors.required).trim(),
  district: yup.string().min(1).required(errors.required).trim(),
  email: yup
    .string()
    .email(errors.format)
    .trim()
    .required(errors.required),
  phone: yup
    .string()
    .trim()
    .matches(/\d+/, errors.format)
    .min(8, "El número debe de ser de 8 dígitos")
    .max(8, "El número debe de ser de 8 dígitos")
    .required(errors.required),
  confirmEmail: yup
    .string()
    .required(errors.required)
    .oneOf([yup.ref("email")], "Debe ser igual al correo electrónico"),
});
