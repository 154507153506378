import AccordionRB from "react-bootstrap/Accordion";
import { Toggle } from "./toggle";

import "./styles.scss";

type Props = {
  defaultActiveKey?: string;
  data: {
    toggle: string | JSX.Element;
    component: JSX.Element;
    eventKey: string;
  }[];
  onChange: (key: string, isActive: boolean) => void;
  type?: "order" | "payment";
  hideArrow?: boolean;
};

const Accordion = ({
  defaultActiveKey,
  data,
  onChange,
  type = "order",
  hideArrow = false,
}: Props) => {
  return (
    <AccordionRB
      defaultActiveKey={defaultActiveKey}
      className="custom-accordion-component-container"
    >
      {data.map((item, index) => (
        <div key={`${type}-accordion-custom-${index}`}>
          <div>
            <Toggle
              type={type}
              eventKey={item.eventKey}
              hideArrow={hideArrow}
              callback={(key, isActive) => onChange(key, isActive)}
            >
              {item.toggle}
            </Toggle>
          </div>
          <AccordionRB.Collapse eventKey={item.eventKey}>
            {item.component}
          </AccordionRB.Collapse>
        </div>
      ))}
    </AccordionRB>
  );
};

export { Accordion };
