const ICONS = {
  whatsapp: {
    class: "bi bi-check-circle-fill",
    path: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z",
    viewBox: "0 0 16 16",
  },
  menu: {
    class: "bi bi-list",
    path: "M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z",
    viewBox: "0 0 16 16",
  },
};

type Props = {
  size?: number;
  color?: string;
  icon: "whatsapp" | "menu";
};

const Icon = ({ size = 20, color = "black", icon }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${size}`}
    height={`${size}`}
    fill={color}
    className={ICONS[icon].class}
    viewBox={ICONS[icon].viewBox}
  >
    <path d={ICONS[icon].path} />
  </svg>
);

export { Icon };
