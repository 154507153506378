import { useContext, FunctionComponent } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";

import { chevronIcon } from "../../assets";

type Props = {
  eventKey: string;
  callback?: (key: string, isOpen: boolean) => void;
  type?: "order" | "payment";
  hideArrow?: boolean;
};

const Toggle: FunctionComponent<Props> = ({
  children,
  eventKey,
  callback,
  type = "order",
  hideArrow = false,
}) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => {
    console.log('here', activeEventKey);
    callback && callback(eventKey, false);
  });

  const rotate = activeEventKey === eventKey ? "rotate(180deg)" : "rotate(0)";

  return (
    <button
      className={`${type}-item-toggle`}
      type="button"
      onClick={decoratedOnClick}
    >
      {children}
      {!hideArrow && (
        <img
          style={{ transform: rotate, transition: "all 0.2s linear" }}
          src={chevronIcon}
          alt=""
        />
      )}
    </button>
  );
};

export { Toggle };
