import ReactDOM from "react-dom";
import { StoreProvider } from "easy-peasy";

import { App } from "./views";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { EventProvider } from "./services/eventEmitter/provider";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

ReactDOM.render(
  <StoreProvider store={store}>
    <EventProvider>
      <App />
    </EventProvider>
  </StoreProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
