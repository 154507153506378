import { createStore, persist } from "easy-peasy";

// State
import { products } from "./products";
import { auth } from "./auth";
import { orders } from "./orders";
import { global } from "./global";

// Model
import { ProductsModel } from "./products/model";
import { AuthModel } from "./auth/model";
import { OrdersModel } from "./orders/model";
import { GlobalModel } from "./global/model";

export interface Store {
  products: ProductsModel;
  auth: AuthModel;
  orders: OrdersModel;
  global: GlobalModel;
}

const store = createStore<Store>(
  persist(
    {
      products,
      auth,
      orders,
      global,
    },
    {
      storage: localStorage,
      allow: ["products", "auth", "orders"],
    }
  )
);

export { store };
