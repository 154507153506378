import "./styles.scss";

type Props = {
  checked: boolean;
  label: string;
  onChange: (value: string) => void;
  value: string | boolean;
  className?: string;
};

const Radio = ({ checked, label, onChange, value, className = '' }: Props) => {
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.currentTarget;
    onChange(id);
  };

  return (
    <label className={`radio-button-component-container ${className}`}>
      {label}
      <input
        type="radio"
        checked={checked}
        name="custom-radio"
        id={`${value}`}
        onChange={(e) => handleRadioChange(e)}
      />
      <span className="checkmark" />
    </label>
  );
};

export { Radio };
