import { Product } from '../store/products/model';
import { request, privateRequest } from './';

export const getProducts = async <T>() => {
    return privateRequest<T>('GET', 'product/all')
}

export const getPublishedProducts = async <T>() => {
    return request<T>('GET', 'product')
}

export const postProduct = async <T>(product: Product) => {
    return privateRequest<T>('POST', 'product', product)
}

export const putProduct = async <T>(product: Product, productId: string) => {
    return privateRequest<T>('PUT', `product/${productId}`, product)
}

export const deleteProduct = async <T>(productId: string) => {
    return privateRequest<T>('DELETE', `product/${productId}`)
}
