import React, { useRef } from "react";

import "./styles.scss";

type Props = {
  placeholder: string;
  onChange: (value: string) => void;
  value?: string;
  error?: string;
  showError?: boolean;
};

const TextArea = ({
  placeholder,
  onChange,
  value = "",
  error,
  showError = false,
}: Props) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const setFocus = () => inputRef.current && inputRef.current.focus();

  const onChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={`text-area-component ${showError && "error"}`}>
      <textarea
        ref={inputRef}
        value={value}
        onChange={onChangeText}
        placeholder=" "
        rows={4}
      />
      <label className="input-placeholder" onClick={setFocus}>
        {placeholder}
      </label>
      {showError && error && (
        <label className="text-area-caption">*{error}</label>
      )}
    </div>
  );
};

export { TextArea };
