const monthString = {
  "01": "Enero",
  "02": "Febrero",
  "03": "Marzo",
  "04": "Mayo",
  "05": "Abril",
  "06": "Junio",
  "07": "Julio",
  "08": "Agosto",
  "09": "Setiembre",
  "10": "Octubre",
  "11": "Noviembre",
  "12": "Diciembre",
};

export const getDateFormat = (time: number, fancy = false) => {
  const dateFormat = new Date(time);
  let month: number | string = dateFormat.getMonth() + 1;

  const addZeroIfNeeded = (num: number) =>
    num < 10 ? "0" + num : num.toString();

  month = addZeroIfNeeded(month);
  const day = addZeroIfNeeded(dateFormat.getDate());
  const year = dateFormat.getFullYear();
  // const mins = addZeroIfNeeded(dateFormat.getMinutes());
  // const secs = addZeroIfNeeded(dateFormat.getSeconds());

  if (fancy) {
    return `${day} de ${(monthString as any)[month]}, ${year}`;
  }

  return `${day}/${month}/${year}`;
};
