import { action, thunk } from "easy-peasy";
import { ProductsModel, Product } from "./model";
import {
  getProducts,
  getPublishedProducts,
  postProduct,
  putProduct,
  deleteProduct,
} from "../../api/products";

const products: ProductsModel = {
  user: {
    data: [],
    currentProduct: undefined,
  },
  portal: {
    data: [],
    currentProduct: undefined,
  },
  loading: false,
  error: undefined,
  removeCurrentProduct: action((state) => {
    state.portal.currentProduct = undefined;
    state.showSuccess = undefined;
  }),
  setCurrentProduct: action((state, payload) => {
    state.showSuccess = undefined;
    state.portal.currentProduct = payload;
  }),
  removeCurrentUserProduct: action((state) => {
    state.user.currentProduct = undefined;
    state.showSuccess = undefined;
  }),
  setCurrentUserProduct: action((state, payload) => {
    state.showSuccess = undefined;
    state.user.currentProduct = payload;
  }),
  fetchProducts: action((state) => {
    state.loading = true;
    state.error = undefined;
  }),
  fetchProductsSuccess: action((state, payload) => {
    state.loading = false;
    state.error = undefined;
    state.portal.data = payload;
  }),
  fetchPublishedProductsSuccess: action((state, payload) => {
    state.loading = false;
    state.error = undefined;
    state.user.data = payload;
  }),
  fetchProductsFailure: action((state, payload) => {
    state.loading = false;
    state.error = payload;
  }),
  getProducts: thunk(async (actions) => {
    actions.fetchProducts();
    const response = await getProducts<Product[]>();

    if (response.data) {
      actions.fetchProductsSuccess(response.data);
    } else {
      actions.fetchProductsFailure(response.errorMessage || "unknow error");
    }
  }),
  getPublishedProducts: thunk(async (actions) => {
    actions.fetchProducts();
    const response = await getPublishedProducts<Product[]>();

    if (response.data) {
      actions.fetchPublishedProductsSuccess(response.data);
    } else {
      actions.fetchProductsFailure(response.errorMessage || "unknow error");
    }
  }),
  addProduct: action((state) => {
    state.loading = true;
    state.error = undefined;
  }),
  addProductSuccess: action((state, payload) => {
    state.loading = false;
    state.error = undefined;
    state.showSuccess = "Se creó el producto perfectamente!";

    if (state.portal.currentProduct) {
      state.portal.currentProduct = { ...state.portal.currentProduct, id: payload.id };
    }
  }),
  addProductFailure: action((state, payload) => {
    state.loading = false;
    state.error = payload;
  }),
  postProduct: thunk(async (actions, payload) => {
    actions.setCurrentProduct(payload);
    actions.addProduct();

    const response = await postProduct<{ id: string }>(payload);

    if (response.data) {
      actions.addProductSuccess(response.data);
    } else {
      actions.addProductFailure(response.errorMessage || "unknow error");
    }
  }),
  updateProduct: action((state) => {
    state.loading = true;
    state.error = undefined;
  }),
  updateProductSuccess: action((state) => {
    state.loading = false;
    state.error = undefined;
    state.showSuccess = "Se actualizó el producto perfectamente!";
  }),
  updateProductFailure: action((state, payload) => {
    state.loading = false;
    state.error = payload;
  }),
  putProduct: thunk(async (actions, payload) => {
    actions.updateProduct();
    const response = await putProduct(payload, payload.id!);

    if (response.data) {
      actions.updateProductSuccess();
    } else {
      actions.updateProductFailure(response.errorMessage || "unknow error");
    }
  }),
  removeProduct: action((state) => {
    state.loading = true;
    state.error = undefined;
  }),
  removeProductSuccess: action((state, payload) => {
    state.loading = false;
    state.error = undefined;
    const newData = state.portal.data.filter((product) => product.id !== payload);
    state.portal.data = newData;
  }),
  removeProductFailure: action((state, payload) => {
    state.loading = false;
    state.error = payload;
  }),
  deleteProduct: thunk(async (actions, payload) => {
    actions.removeProduct();
    const response = await deleteProduct(payload);

    if (response.data) {
      actions.removeProductSuccess(payload);
    } else {
      actions.removeProductFailure(response.errorMessage || "unknow error");
    }
  }),
};

export { products };
